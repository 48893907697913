import { RingVolume as RingVolumeIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { amber, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import Box from '@/components/card-box';
import { WithUtils, withUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import { stopTypeNames } from '@/lib/stop-type-names';

const styles = (theme: Theme) => ({
  // TODO: Re-factor this, it's used in the Andon overview as well.
  '@keyframes pulseUrgencyCritical': {
    '0%': { color: red['800'] },
    '50%': { color: 'inherit' },
    '100%': { color: red['800'] },
  },
  '@keyframes pulseUrgencyMedium': {
    '0%': { color: amber['600'] },
    '50%': { color: 'inherit' },
    '100%': { color: amber['600'] },
  },
  buttonUrgencyCritical: {
    animationName: '$pulseUrgencyCritical',
  },
  buttonUrgencyMedium: {
    animationName: '$pulseUrgencyMedium',
  },
  andonIndicator: {
    marginLeft: '1em',
    fontSize: 'inherit',
    color: 'inherit',
    animationIterationCount: 'infinite',
    animationDuration: '3s',
  },
  cardContentClass: {
    padding: '0 !important',
    paddingBottom: '0px !important',
  },
});

interface Properties {
  className?: string;
  lineStatus: {
    statusText: string;
    statusColor: string;
    lineDuration: number;
    unresolvedCall?: Schema.Urgency;
  };
  wide?: boolean;
  small?: boolean;
  moreStyle?: React.CSSProperties;
  sensorType?: Schema.SensorType;
}
interface State {}
class LineStatus extends React.Component<
  Properties & WithTranslation & WithUtils & WithStyles<typeof styles> & { theme: Theme },
  State
> {
  public render() {
    const {
      className,
      moreStyle,
      lineStatus: { statusText, statusColor, lineDuration, unresolvedCall },
      small,
      utils,
      sensorType,
      t,
      theme,
      classes,
      wide = true,
    } = this.props;

    let stopText = statusText;
    if (stopTypeNames(t)[statusText as keyof typeof stopTypeNames]) {
      stopText = stopTypeNames(t)[statusText as keyof typeof stopTypeNames];
    }

    let showDuration = true;
    if (sensorType === Schema.SensorType.MEASUREMENT) {
      showDuration = false;
    }
    let hasNeverBeenOnline = false;
    let durationString = null;
    try {
      // If it has never been online since 2000-01-01, then it has literally never been online.
      const now = new Date().getTime();
      if (new Date(now - lineDuration) < new Date('2000-01-01')) {
        hasNeverBeenOnline = true;
      }
    } catch (_err) {}
    if (!hasNeverBeenOnline) {
      const lineDurationDays = lineDuration - (lineDuration % (24 * 3600 * 1000));
      const lineDurationHours = lineDuration - lineDurationDays - (lineDuration % (3600 * 1000));
      const lineDurationMinutes = lineDuration - lineDurationDays - lineDurationHours - (lineDuration % (1000 * 60));
      const lineDurationSeconds =
        lineDuration - lineDurationDays - lineDurationHours - lineDurationMinutes - (lineDuration % 1000);
      const lineDurationMiliseconds =
        lineDuration - lineDurationDays - lineDurationHours - lineDurationMinutes - lineDurationSeconds - lineDuration;
      if (lineDuration < 1000) {
        durationString = lineDurationMiliseconds
          ? utils.formatDistanceStrict(0, lineDurationMiliseconds / 1000, { roundingMethod: 'floor', unit: 'second' })
          : '';
      } else if (lineDuration < 60000) {
        durationString = lineDurationSeconds
          ? utils.formatDistanceStrict(0, lineDurationSeconds, { roundingMethod: 'floor', unit: 'second' })
          : '';
      } else {
        durationString = [
          lineDurationDays
            ? utils.formatDistanceStrict(0, lineDurationDays, { roundingMethod: 'floor', unit: 'day' })
            : '',
          lineDurationHours
            ? utils.formatDistanceStrict(0, lineDurationHours, { roundingMethod: 'floor', unit: 'hour' })
            : '',
          lineDurationMinutes && (!unresolvedCall || wide)
            ? utils.formatDistanceStrict(0, lineDurationMinutes, { roundingMethod: 'floor', unit: 'minute' })
            : '',
        ]
          .filter((value) => value)
          .join(', ');
      }
    } else {
      durationString = t(['shared:forever'], { defaultValue: 'Forever' });
    }
    return (
      <Box
        small={small}
        backgroundColor={statusColor}
        className={className}
        cardContentClass={classes.cardContentClass}
      >
        <Typography
          className={className}
          variant="body2"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.getContrastText(statusColor),
            ...moreStyle,
          }}
          noWrap
        >
          {` ${stopText} `}
          {showDuration && (
            <>
              {durationString ? ' - ' : ''}
              {durationString}
            </>
          )}
          {!!unresolvedCall ? (
            <RingVolumeIcon
              className={clsx(classes.andonIndicator, {
                [classes.buttonUrgencyCritical]: unresolvedCall === Schema.Urgency.CRITICAL,
                [classes.buttonUrgencyMedium]: unresolvedCall === Schema.Urgency.MEDIUM,
              })}
            />
          ) : null}
        </Typography>
      </Box>
    );
  }
}

const enhance = compose<unknown, Properties>(
  withTranslation(['shared']),
  withUtils,
  withStyles(styles, { withTheme: true }),
);

export default enhance(LineStatus as React.ComponentType<unknown>);

import React, { FunctionComponent } from 'react';

const SVG_NS = 'http://www.w3.org/2000/svg';

type TargetValueProps = {
  targetValue: number;
  dialRadius?: number;
  dialStartAngle?: number;
  dialEndAngle?: number;
  gaugeCenterX?: number;
  gaugeCenterY?: number;
  gaugeClass?: string;
};

const OEETargetValue: FunctionComponent<TargetValueProps> = (props) => {
  const {
    dialRadius = 40,
    dialStartAngle = 135,
    dialEndAngle = 45,
    gaugeCenterX = 50,
    gaugeCenterY = 50,
    targetValue,
    gaugeClass,
  } = props;

  const validatedTargetValue = validateTargetValue(targetValue);
  const startValue = validatedTargetValue - 0.5;

  const startAngle = getAngle(startValue, 360 - Math.abs(dialStartAngle - dialEndAngle));
  const endAngle = getAngle(validatedTargetValue, 360 - Math.abs(dialStartAngle - dialEndAngle));

  const coords = getDialCoords(
    gaugeCenterX,
    gaugeCenterY,
    dialRadius,
    startAngle + dialStartAngle,
    endAngle + dialStartAngle,
  );
  return (
    <svg
      height={'100%'}
      width={'100%'}
      xmlns={SVG_NS}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      className={gaugeClass}
    >
      <line
        fill="none"
        stroke="#666"
        strokeWidth={8}
        x1={coords.start.x}
        y1={coords.start.y}
        x2={coords.end.x}
        y2={coords.end.y}
      />
      {getSVGText(validatedTargetValue)}
    </svg>
  );
};

export default OEETargetValue;

const getSVGText = (targetValue: number) => {
  return (
    <text fill="grey" textAnchor="middle" fontSize="40%" fontFamily="sans-serif" x="50" y="63">
      Target: {targetValue.toFixed(1)}%
    </text>
  );
};

const validateTargetValue = (targetValue: number) => {
  return Math.max(Math.min(targetValue, 100), 0);
};

/**
 * Gets cartesian co-ordinates for a specified radius and angle (in degrees)
 * @param cx {Number} The center x co-oriinate
 * @param cy {Number} The center y co-ordinate
 * @param radius {Number} The radius of the circle
 * @param angle {Number} The angle in degrees
 * @return An object with x,y co-ordinates
 */
const getCartesian = (cx: number, cy: number, radius: number, angle: number) => {
  var rad = (angle * Math.PI) / 180;
  return {
    x: Math.round((cx + radius * Math.cos(rad)) * 1000) / 1000,
    y: Math.round((cy + radius * Math.sin(rad)) * 1000) / 1000,
  };
};

// Returns start and end points for dial
// i.e. starts at 135deg ends at 45deg with large arc flag
// REMEMBER!! angle=0 starts on X axis and then increases clockwise
const getDialCoords = (cx: number, cy: number, radius: number, startAngle: number, endAngle: number) => {
  return {
    end: getCartesian(cx, cy, radius, endAngle),
    start: getCartesian(cx, cy, radius, startAngle),
  };
};

/**
 * Translates percentage value to angle. e.g. If gauge span angle is 180deg, then 50%
 * will be 90deg
 */
const getAngle = (percentage: number, gaugeSpanAngle: number) => {
  return (percentage * gaugeSpanAngle) / 100;
};

import * as i18next from 'i18next';

export const stopTypeNames = (t: i18next.TFunction) => ({
  DOWNTIME: t(['stop:lossDuringOperation'], { defaultValue: 'Loss During Operation' }),
  NO_ACT: t(['stop:noActivityAtLine'], { defaultValue: 'No Activity at Line' }),
  NON_PROD: t(['stop:nonProductionActivities'], { defaultValue: 'Non-production Activities' }),
  BATCH_NON_PROD: t(['stop:batchSpecificNonOperation'], { defaultValue: 'Batch-specific Non-operation' }),
  UNREGISTERED: t(['shared:unregistered'], { defaultValue: 'Unregistered' }),
  MICRO_STOP: t(['shared:microStop'], { defaultValue: 'Micro stop' }),
});

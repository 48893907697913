import * as ApolloCommon from '@apollo/client';
import { ApolloError } from '@apollo/client';
import * as Apollo from '@apollo/client/react/components';
import { useLocalStorage } from '@blackbird/ui-base';
import * as Schema from 'generated/graphql/schema';
import React, { useState } from 'react';

import * as Constants from '@/constants';
import { lineOverviewWithKPI, linesListSimple } from '@/graphql/queries';
import { getYearWeek } from '@/helpers/schedule';
import { useIAM } from '@/hooks/use-iam';
import { updateDataHash } from '@/lib/hash';
import * as Types from '@/types';
import { Module } from '@/types/auth-types';
import LineOverviewCard from '@/views/lines/line-card';
import { CardConfiguration } from '@/views/lines/overview-components/menu';

interface LineCards {
  filteredLines: Schema.LinesListSimpleQuery['lines'];
  currentPage: number;
  pageSize: number;
  timeAtLoad: Date;
  cardConf: CardConfiguration;
  cachePolicy: ApolloCommon.BaseQueryOptions['fetchPolicy'];
}

const checkForMissingMainSensor = (setMainSensorConfigurationError: (e: boolean) => void) => (error: ApolloError) => {
  // Check if we are getting an error because of a missing main sensor.
  if (
    error?.graphQLErrors?.[0]?.path?.length === 2 &&
    error?.graphQLErrors?.[0]?.path?.[0] === 'line' &&
    error?.graphQLErrors?.[0]?.path?.[1] === 'mainSensor'
  ) {
    setMainSensorConfigurationError(true);
  }
};

// We extract the query, so that we can maintain a state for each Apollo query.
const InternalLineCard = ({
  cardConf,
  cachePolicy,
  lineSimple,
  lineVariables,
}: Pick<LineCards, 'cardConf' | 'cachePolicy'> & {
  lineSimple: LineCards['filteredLines'][0];
  lineVariables: Schema.LineOverviewWithKpiQueryVariables;
}) => {
  const [dataHash, setDataHash] = useState('');
  const [mainSensorConfigurationError, setMainSensorConfigurationError] = useState(false);
  return (
    <Apollo.Query<Schema.LineOverviewQuery, Schema.LineOverviewWithKpiQueryVariables>
      ssr={false}
      partialRefetch
      fetchPolicy={cachePolicy}
      // FIXME: Batching currently screws up Line Status.
      // context={{ batchFour: true }}
      skip={mainSensorConfigurationError}
      errorPolicy="all" // Necessary for returning partial data.
      query={lineOverviewWithKPI}
      onCompleted={updateDataHash(setDataHash)}
      onError={checkForMissingMainSensor(setMainSensorConfigurationError)}
      variables={lineVariables}
    >
      {({ data, loading }) => {
        const line = data?.line ?? (lineSimple as Schema.LineOverviewWithKpiQuery['line']);
        return (
          <LineOverviewCard
            key={line.id}
            line={line}
            loading={loading}
            mainSensorConfigurationError={mainSensorConfigurationError}
            dataHash={dataHash}
            refetchQueries={[{ query: lineOverviewWithKPI, variables: lineVariables }]}
            deleteRefetchQueries={[{ query: linesListSimple, variables: {} }]}
            conf={cardConf}
          />
        );
      }}
    </Apollo.Query>
  );
};

const LineCards = React.memo(
  ({ filteredLines, currentPage, pageSize, timeAtLoad, cardConf, cachePolicy }: LineCards) => {
    const { hasModuleAccess } = useIAM();
    const [pinnedLines] = useLocalStorage<string[]>([Constants.LOCAL_STORAGE_PINNED, 'LINES'].join('_'), []);
    const mostRecentMinute = Math.round(timeAtLoad.getTime() / (1).minutes) * (1).minutes;
    const currentPageLines = [...filteredLines]
      .sort((l) => (pinnedLines.includes(l.id) ? -1 : 1))
      .slice(currentPage * pageSize, currentPage * pageSize + pageSize);
    const timeInterval = cardConf?.timeInterval ?? 8;

    if (!currentPageLines?.length) {
      return null;
    }

    const now = new Date(mostRecentMinute);
    const validIn = getYearWeek(now);
    const weekDayNum = (now.getDay() || 7) - 1;

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {currentPageLines.map((lineSimple) => {
          const lineVariables: Schema.LineOverviewWithKpiQueryVariables = {
            lineId: lineSimple.id,
            time: [
              Types.toISOString({
                from: new Date(mostRecentMinute - timeInterval.hours),
                to: now,
              }),
            ],
            points: Constants.SAMPLE_POINTS,
            showStops: cardConf.showStops === true || cardConf.countUnregisteredStops === true ? true : false,
            showOee: cardConf.showOee,

            showWeeklyProgress: !!cardConf.showWeeklyProgress,
            showDailyProgress: !!cardConf.showDailyProgress,
            hasMaintenanceModule: hasModuleAccess(Module.Maintenance),
            validIn,
            pastWeek: [
              Types.toISOString({
                to: now,
                from: new Date(new Date(mostRecentMinute - weekDayNum * (1).days).setHours(0, 0, 0)),
              }),
            ],
            pastDay: [
              Types.toISOString({
                to: now,
                from: new Date(new Date(mostRecentMinute - (1).days).setHours(0, 0, 0)),
              }),
            ],
          };
          return (
            <InternalLineCard
              key={`lineSimple:${lineSimple.id}`}
              lineSimple={lineSimple}
              lineVariables={lineVariables}
              cardConf={cardConf}
              cachePolicy={cachePolicy}
            />
          );
        })}
      </>
    );
  },
  (prevProps: LineCards, nextProps: LineCards) => {
    if (
      prevProps.filteredLines?.length !== nextProps.filteredLines?.length ||
      prevProps.currentPage !== nextProps.currentPage ||
      prevProps.pageSize !== nextProps.pageSize ||
      prevProps.cardConf !== nextProps.cardConf ||
      (prevProps.cardConf &&
        nextProps.cardConf &&
        prevProps.cardConf.timeInterval !== nextProps.cardConf.timeInterval) ||
      prevProps.timeAtLoad !== nextProps.timeAtLoad
    ) {
      return false;
    }
    return true;
  },
);
export default LineCards;

import { PureQueryOptions } from '@apollo/client';
import { ImperceptibleLink } from '@blackbird/ui-base/link';
import { Build as MaintenanceIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import * as Schema from 'generated/graphql/schema';
import { isEqual } from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import * as Highcharts from '@/components/containers/highcharts';
import SignalAvatar from '@/components/icons/signal-avatar';
import * as Constants from '@/constants';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import getLineStatusInfo from '@/lib/get-line-status';
import ManualProcessConfig from '@/lib/highcharts/manual-process-config';
import OverviewChartConfig from '@/lib/highcharts/overview-config';
import * as Types from '@/types';
import LineOverviewCardMenu from '@/views/lines/line-card-menu';
import LineOverviewInfo from '@/views/lines/overview-components/components';
import { CardConfiguration } from '@/views/lines/overview-components/menu';

const LineSettingsDialog = dynamic(async () => import('@/views/dialogs/line-settings/line-settings-dialog'));

const styles = (theme: Theme) => ({
  card: {
    height: '100%',
    transition: 'box-shadow .25s',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  anchor: {
    textDecoration: 'none',
    color: 'black',
  },
  actions: {
    paddingLeft: theme.spacing(),
    paddingBottom: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    margin: theme.spacing(),
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '180px',
    paddingTop: '50px',
  },
  lineIcon: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(-0.5),
  },
});

const countOverdueTasks = (line: Types.DeepPartial<Schema.Line> | undefined) => {
  if (!line?.nodes) {
    return 0;
  }

  return line.nodes.reduce((acc, node) => {
    const tasks = node?.sensor?.maintenanceUpcomingTasks?.nodes ?? [];
    if (tasks.length === 0) {
      return acc;
    }

    if (new Date(tasks[0]?.timestamp ?? 0) < new Date()) {
      // Task is overdue
      return acc + 1;
    }

    return acc;
  }, 0);
};

interface Properties {
  loading: boolean;
  line: Omit<Schema.LineOverviewQuery['line'], 'nodes' | 'edges'> & Types.DeepPartial<Schema.Line>;
  refetchQueries?: PureQueryOptions[];
  deleteRefetchQueries?: PureQueryOptions[];
  conf: CardConfiguration;
  dataHash: string;
  mainSensorConfigurationError?: boolean;
}

interface State {
  isHover: boolean;
  settingsDialogOpen: boolean;
  dataHash: string;
}

type ExtendedProperties = Properties & WithTranslation & WithStyles<typeof styles>;

class LineOverviewCard extends React.Component<ExtendedProperties, State> {
  readonly state: State = {
    isHover: false,
    settingsDialogOpen: false,
    dataHash: '',
  };

  shouldComponentUpdate(nextProps: ExtendedProperties, nextState: State) {
    if (
      nextProps.loading !== this.props.loading ||
      nextProps.dataHash !== this.props.dataHash ||
      nextState.dataHash !== this.state.dataHash ||
      nextProps.mainSensorConfigurationError !== this.props.mainSensorConfigurationError ||
      !isEqual(nextProps.conf, this.props.conf)
    ) {
      return true;
    } else if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  static getDerivedStateFromProps(nextProps: Readonly<ExtendedProperties>, prevState: State) {
    if (nextProps.dataHash !== prevState.dataHash && nextProps.conf?.showGraph !== false) {
      return {
        dataHash: nextProps.dataHash,
      };
    } else if (nextProps.conf?.showGraph === false) {
      return {
        dataHash: '',
      };
    }
    return null;
  }

  // We unset the datahash so that highcharts will rerender when we mount the component again.
  componentWillUnmount() {
    this.setState({
      dataHash: '',
    });
  }

  render() {
    const { conf, classes, line, t, i18n, refetchQueries, deleteRefetchQueries, theme, mainSensorConfigurationError } =
      this.props;
    const { settingsDialogOpen, dataHash } = this.state;
    const { id, name, description } = line;
    const mainSensor = line?.mainSensor;
    const { status, duration, cause } = mainSensor?.time?.[0]?.stats?.data?.lineStatus || {};
    const chartLoading = mainSensor ? false : true;
    let stopRegisterThreshold = mainSensor?.config?.stopRegisterThreshold ?? (10).seconds;

    const { statusColor, statusText, lineDuration } = getLineStatusInfo(
      t,
      theme,
      status,
      duration ?? 0,
      stopRegisterThreshold,
      cause ?? 'Pending',
    );

    const strength =
      (mainSensor?.time?.[0]?.stats?.data?.lineStatus?.status ?? Schema.Status.OFF) === Schema.Status.OFF ? 0 : 4;

    // Check if we are getting an error because of a missing main sensor.
    let errorMessage: string | undefined;
    if (mainSensorConfigurationError === true) {
      errorMessage = t(['line:noMainSensorConfigured'], { defaultValue: 'No main sensor configured' });
    }

    const overdueTaskCount = countOverdueTasks(line);

    return (
      <Grid item xs={12} sm={12} md={conf.oneLineCardEachRow ? 12 : 6}>
        <Card className={classes.card} elevation={1}>
          <ImperceptibleLink href={{ pathname: '/line', query: { lineId: `${id}`, tab: 'live' } }}>
            <span className={classes.anchor}>
              <Grid container sx={{ px: 3, py: 2 }} alignItems="center">
                <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                  <Typography variant="body1">
                    <Constants.LINE_ICON
                      style={{ color: statusColor }}
                      className={clsx('rtl-margin-left', `${classes.lineIcon} hideInPercy`)}
                    />
                    {name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2" noWrap>
                    {description}
                  </Typography>
                </div>
                {overdueTaskCount > 0 && (
                  <Link href="/maintenance">
                    <Tooltip
                      title={t(['maintenance:maintenanceRequired'], {
                        defaultValue: 'Maintenance required for one or more components',
                      })}
                    >
                      <IconButton size="large" sx={{ mr: 1 }}>
                        <MaintenanceIcon sx={{ color: red[700] }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}
                <SignalAvatar conn={{ strength, type: 'wifi' }} />
                <LineOverviewCardMenu lineId={id} mainSensor={line.mainSensor} openDialog={this.openDialog} />
              </Grid>
              <CardContent
                className={'hideInPercy'}
                style={{ paddingBottom: 0, padding: !conf.showGraph ? 0 : undefined }}
              >
                {mainSensor?.config?.type !== Schema.SensorType.MANUAL_PROCESS ? (
                  <Highcharts.Graph
                    compareForUpdate={`${dataHash}-${conf.showStops}-${conf.showStopLabels}-${conf.oneLineCardEachRow}`}
                    aggressivelyMinimizeRendering
                    configOptions={{
                      disableAxisLabel: mainSensorConfigurationError,
                      t,
                      language: i18n.language,
                      height: '180px',
                      sensor: mainSensor,
                      theme,
                      showStops: conf.showStops,
                      showStopLabels: conf.showStopLabels,
                    }}
                    hideChart={!conf.showGraph}
                    configFn={OverviewChartConfig}
                    loading={chartLoading}
                    error={mainSensorConfigurationError}
                    errorMsg={errorMessage}
                  />
                ) : (
                  <Highcharts.Graph
                    compareForUpdate={`${dataHash}-${conf.showStops}-${conf.showStopLabels}-${conf.oneLineCardEachRow}`}
                    aggressivelyMinimizeRendering
                    configOptions={{
                      t,
                      chartHeight: '180px',
                      data: mainSensor,
                      theme,
                      disableLabels: !conf.showStopLabels,
                    }}
                    hideChart={!conf.showGraph}
                    configFn={ManualProcessConfig}
                    loading={chartLoading}
                    error={mainSensorConfigurationError}
                    errorMsg={errorMessage}
                  />
                )}
              </CardContent>
              <Box p={1}>
                <LineOverviewInfo lineStatus={{ statusText, statusColor, lineDuration }} line={line} conf={conf} />
              </Box>
            </span>
          </ImperceptibleLink>
        </Card>
        {settingsDialogOpen ? (
          <LineSettingsDialog
            lineId={line.id}
            handleOpenClose={this.handleDialogChange}
            open={this.state.settingsDialogOpen}
            refetchQueries={refetchQueries}
            deleteRefetchQueries={deleteRefetchQueries}
          />
        ) : null}
      </Grid>
    );
  }
  private handleDialogChange = (open: boolean) => this.setState({ settingsDialogOpen: open });
  private openDialog = () => this.setState({ settingsDialogOpen: true });
}
const enhance = compose<unknown, Properties>(
  withTranslation(['shared', 'line', 'device']),
  withStyles(styles, { withTheme: true }),
);
export default enhance(LineOverviewCard as React.ComponentType<unknown>);

import React from 'react';

import { LineContext } from '@/contexts/line';

export interface WithLine {
  lineId: string | undefined;
  lineName: string | undefined;
}

// tslint:disable:max-classes-per-file
export const withLine = (WrappedComponent: React.ComponentType<WithLine>) => {
  return class WithLineWrapper extends React.PureComponent {
    public render() {
      return (
        <LineContext.Consumer>
          {({ lineId, lineName }) => <WrappedComponent lineId={lineId} lineName={lineName} {...this.props} />}
        </LineContext.Consumer>
      );
    }
  };
};

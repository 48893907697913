export const hashCode = (s: string | undefined): string => {
  let hash = 0;
  if (!s?.length) {
    return String(hash);
  }
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }
  return String(hash);
};

export const updateDataHash =
  (setDataHash: (hash: string) => void) =>
  (data: object): void => {
    if (data) {
      const hash = hashCode(JSON.stringify(data));
      setDataHash(hash);
    }
  };

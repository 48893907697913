import * as React from 'react';

export interface Properties {
  lineId?: string;
  lineName?: string;
}

export const LineContext = React.createContext<Properties>({
  lineId: undefined,
  lineName: undefined,
});

import { Cancel as CancelIcon, Search } from '@mui/icons-material';
import { Avatar, Chip, Grid, MenuItem, NoSsr, Paper, TextField, Typography } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { InputProps } from '@mui/material/Input';
import { Theme, emphasize } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { components as defaultReactSelectComponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

export interface SearchValue {
  label: string;
  value: {
    type: 'string' | 'group' | 'type';
    value: string;
  };
}

const styles = (theme: Theme) => ({
  root: {
    flex: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: theme.spacing(5),
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage<
  T extends {
    selectProps: { classes?: { noOptionsMessage: string }; [key: string]: any };
    innerProps: Record<string, any>;
    children: React.ReactNode;
  },
>(props: T) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes?.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }: React.HTMLAttributes<HTMLDivElement> & Pick<InputProps, 'inputRef'>) {
  // NOTE: Order is important, InputBase sets ref to null, thus we ensure it's
  // value by setting ref after the spread.
  return <div {...props} ref={inputRef} />;
}

function Control<
  T extends {
    selectProps: { classes?: { input: string }; [key: string]: any };
    innerProps: Record<string, any>;
    innerRef: React.Ref<HTMLDivElement>;
    children: React.ReactNode;
  },
>(props: T) {
  return (
    <TextField
      variant="filled"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes?.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option<
  T extends {
    children: React.ReactNode;
    innerProps: Record<string, any>;
    innerRef: React.Ref<HTMLDivElement>;
    isFocused: boolean;
    isSelected: boolean;
  },
>(props: T) {
  return (
    <div style={{ display: 'flex', minWidth: 0, padding: '5px' }}>
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
          width: '100%',
        }}
        {...props.innerProps}
      >
        <span
          style={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {' '}
          {props.children}{' '}
        </span>
      </MenuItem>
    </div>
  );
}

function Placeholder<
  T extends {
    selectProps: { classes?: { placeholder: string }; [key: string]: any };
    children: React.ReactNode;
    innerProps: Record<string, any>;
  },
>(props: T) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes?.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue<
  T extends {
    selectProps: { classes?: { singleValue: string }; [key: string]: any };
    children: React.ReactNode;
    innerProps: Record<string, any>;
  },
>(props: T) {
  return (
    <Typography className={props.selectProps.classes?.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer<
  T extends {
    selectProps: { classes?: { valueContainer: string }; [key: string]: any };
    children: React.ReactNode;
  },
>(props: T) {
  return <div className={props.selectProps.classes?.valueContainer}>{props.children}</div>;
}

function MultiValue<
  T extends {
    removeProps: { onClick: ChipProps['onDelete'] };
    selectProps: { classes?: { chip: string; chipFocused: string }; [key: string]: any };
    children: React.ReactNode;
    isFocused: boolean;
  },
>(props: T) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes?.chip, {
        [props.selectProps.classes?.chipFocused!]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu<
  T extends {
    selectProps: { classes?: { paper: string }; [key: string]: any };
    children: React.ReactNode;
    innerProps: Record<string, any>;
  },
>(props: T) {
  return (
    <Paper square className={props.selectProps.classes?.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components: Partial<typeof defaultReactSelectComponents> = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const createOption = (label: string): SearchValue => ({
  label,
  value: {
    type: 'string',
    value: label,
  },
});

interface Properties {
  handleChange: (val: SearchValue[]) => void;
  value: SearchValue[];
  suggestions: SearchValue[];
}

interface State {
  inputValue: string;
}

class SearchBar extends React.Component<Properties & WithTranslation & WithStyles<typeof styles>, State> {
  readonly state = {
    inputValue: '',
  };

  public render() {
    const { classes, value, theme, suggestions, t } = this.props;

    const selectStyles = {
      input: (base: object) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Grid container>
            <Grid item>
              <Avatar
                style={{
                  marginRight: theme.spacing(),
                  backgroundColor: '#269AE0',
                }}
              >
                <Search />
              </Avatar>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <CreatableSelect
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                createOptionPosition={'first'}
                formatCreateLabel={this.formatLabel}
                options={suggestions}
                components={components}
                value={value}
                onCreateOption={this.createOption}
                onChange={this.handleChange}
                placeholder={`${t(['shared:typeToSearch'], { defaultValue: 'Type to search' })}...`}
                isMulti
                isClearable
              />
            </Grid>
          </Grid>
        </NoSsr>
      </div>
    );
  }
  private formatLabel = (val: string) =>
    `${this.props.t(['shared:searchFor'], { defaultValue: 'Search for' })} "${val}"`;

  private createOption = (inputValue: string) =>
    this.props.handleChange([...this.props.value, createOption(inputValue)]);

  private handleChange = (val: SearchValue | ReadonlyArray<SearchValue> | null | undefined) => {
    this.props.handleChange(Array.isArray(val) ? val : val ? [val] : []);
  };
}
const enhance = compose<unknown, Properties>(withTranslation(['shared']), withStyles(styles, { withTheme: true }));
export default enhance(SearchBar as React.ComponentType<unknown>);

import * as Schema from 'generated/graphql/schema';
import * as React from 'react';

import OverviewTopBar from '@/components/bars/overview-top-bar';
import { SearchValue } from '@/components/bars/search-bar';
import ConfigurationMenu, { CardConfiguration, TimeInterval } from '@/views/lines/overview-components/menu';

interface Properties {
  search: SearchValue[];
  page: number;
  pageSize: number;
  filteredLines: Schema.LinesListSimpleQuery['lines'];
  cardConf: CardConfiguration;
  updateInterval: number | null;
  nextLiveUpdate: Date | null;
  handleSearchChange: (search: SearchValue[]) => void;
  handlePageChange: (page: number) => void;
  updateConf: (key: string, check: boolean) => void;
  updateIntervalConf: (interval: TimeInterval) => void;
  updateRefreshIntervalConf: (newInterval: number | null) => void;
}

interface State {}

class LinesTopBar extends React.Component<Properties, State> {
  public shouldComponentUpdate(nextProps: Properties) {
    if (
      nextProps.filteredLines.length !== this.props.filteredLines.length ||
      nextProps.search.length !== this.props.search.length ||
      nextProps.page !== this.props.page ||
      nextProps.cardConf !== this.props.cardConf ||
      nextProps.nextLiveUpdate !== this.props.nextLiveUpdate ||
      nextProps.updateInterval !== this.props.updateInterval
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      search,
      page,
      filteredLines,
      pageSize,
      cardConf,
      handlePageChange,
      handleSearchChange,
      updateConf,
      updateIntervalConf,
      updateRefreshIntervalConf,
      updateInterval,
      nextLiveUpdate,
    } = this.props;

    const suggestions: SearchValue[] = this.generateSuggestions();

    const totalCount = filteredLines.length;

    return (
      <OverviewTopBar
        search={search}
        suggestions={suggestions}
        page={page}
        pageSize={pageSize}
        totalCount={totalCount}
        handlePageChange={handlePageChange}
        handleSearchChange={handleSearchChange}
      >
        <ConfigurationMenu
          cardConf={cardConf}
          updateConf={updateConf}
          updateIntervalConf={updateIntervalConf}
          updateInterval={updateInterval}
          updateRefreshIntervalConf={updateRefreshIntervalConf}
          nextLiveUpdate={nextLiveUpdate}
        />
      </OverviewTopBar>
    );
  }

  private generateSuggestions = (): SearchValue[] => {
    const { search, filteredLines } = this.props;
    // Store all the suggestions in a map, to avoid duplicates.
    const suggestions: { [key: string]: SearchValue } = {};

    filteredLines.forEach((line) => {
      // If we haven't started searching yet, include all results.
      if (!search.length) {
        // Ignore empty names.
        if ((line?.name ?? '').toLowerCase()) {
          suggestions[(line?.name ?? '').toLowerCase()] = {
            label: line?.name ?? '',
            value: {
              type: 'string',
              value: (line?.name ?? '').toLowerCase(),
            },
          };
        }
        // Ignore empty descriptions.
        if ((line?.description ?? '').toLowerCase()) {
          suggestions[(line?.description ?? '').toLowerCase()] = {
            label: line?.description ?? '',
            value: {
              type: 'string',
              value: (line?.description ?? '').toLowerCase(),
            },
          };
        }
      } else {
        // Filter out both results and remaining suggestions, based on the search filter.
        let foundInSearch = false;
        search.map((searchTerm) => {
          if (searchTerm && searchTerm.value && searchTerm.value.value) {
            foundInSearch =
              (line?.name ?? '').toLowerCase().includes(searchTerm.value.value.toLowerCase()) ||
              (line?.description ?? '').toLowerCase().includes(searchTerm.value.value.toLowerCase());
            if (foundInSearch) {
              suggestions[(line?.name ?? '').toLowerCase()] = {
                label: line?.name ?? '',
                value: {
                  type: 'string',
                  value: (line?.name ?? '').toLowerCase(),
                },
              };
              suggestions[(line?.description ?? '').toLowerCase()] = {
                label: line?.description ?? '',
                value: {
                  type: 'string',
                  value: (line?.description ?? '').toLowerCase(),
                },
              };
            }
          }
        });
      }
    });

    // Convert the map to an array of SearchValue.
    return Object.keys(suggestions).map((k) => {
      return suggestions[k];
    });
  };
}

export default LinesTopBar;

import * as ApolloCommon from '@apollo/client';
import * as Apollo from '@apollo/client/react/components';
import { Button, Grid, Hidden, Theme, Typography } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import { WithRouterProps } from 'next/dist/client/with-router';
import Router, { withRouter } from 'next/router';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { UrlObject, format } from 'url';

import { SearchValue } from '@/components/bars/search-bar';
import StickyFab from '@/components/buttons/sticky-fab';
import { CreateLineDialog } from '@/components/dialogs/create-line/create-line-dialog';
import LoadingOrError from '@/components/loading/loading-or-error';
import Paginator from '@/components/paginator';
import * as Constants from '@/constants';
import { linesListSimple } from '@/graphql/queries';
import { WithIAM, WithLine, WithStyles, withIAM, withLine, withStyles } from '@/hocs';
import { WithWidth, withWidth } from '@/hocs/with-width';
import LineCards from '@/views/lines/overview-cards';
import {
  CardConfiguration,
  TimeInterval,
  defaultCardConf,
  instanceOfCardConf,
} from '@/views/lines/overview-components/menu';
import LinesTopBar from '@/views/lines/overview-top-bar';

const styles = (theme: Theme) => ({
  button: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  message: {
    paddingBottom: theme.spacing(2),
  },
  lightBtn: {
    color: '#fff',
  },
});

/**
 * Only accept valid cache policies.
 */
function isCachePolicy(
  val: string | ApolloCommon.BaseQueryOptions['fetchPolicy'],
): val is ApolloCommon.BaseQueryOptions['fetchPolicy'] {
  const allowed: Array<ApolloCommon.BaseQueryOptions['fetchPolicy']> = [
    'cache-first',
    'network-only',
    'cache-only',
    'no-cache',
    'standby',
    'cache-and-network',
  ];
  return allowed.includes(val as ApolloCommon.BaseQueryOptions['fetchPolicy']);
}

interface Properties {
  // Align all data times, by getting the date from the parent component.
  timeAtLoad: Date;
  updateInterval: number | null;
  changeUpdateInterval: (newInterval: number | null) => void;
}

interface State {
  search: SearchValue[];
  page: number;
  createModalOpen: boolean;
  device: string;
  cardConf: CardConfiguration;
  kpiOptions:
    | Array<{
        string: boolean;
      }>
    | [];

  cachePolicy: ApolloCommon.BaseQueryOptions['fetchPolicy'];
}
type ExtendedProperties = Properties &
  WithTranslation &
  WithLine &
  WithRouterProps &
  WithIAM &
  WithStyles<typeof styles> &
  WithWidth;

class LineCardPage extends React.Component<ExtendedProperties, State> {
  readonly state: State = {
    search: [],
    page: 0,
    createModalOpen: false,
    device: '',
    cardConf: defaultCardConf,
    kpiOptions: [],
    cachePolicy: 'no-cache',
  };

  private pageSize = Constants.PAGE_SIZE;

  constructor(props: ExtendedProperties) {
    super(props);

    try {
      if (typeof Storage !== 'undefined') {
        try {
          const layoutConfiguration = JSON.parse(
            window.localStorage.getItem(Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG)!,
          );
          if (instanceOfCardConf(layoutConfiguration)) {
            this.state.cardConf = {
              ...this.state.cardConf,
              ...layoutConfiguration,
            };
          }

          // tslint:disable-next-line:no-empty
        } catch (_) {}
      }
      if (props.router.query.search) {
        try {
          const searchQuery = Array.isArray(props.router.query.search)
            ? props.router.query.search[0]
            : props.router.query.search;
          this.state.search = JSON.parse(searchQuery);
        } catch (routerError) {
          console.error(routerError);
        }
      }
      // NOTE: We allow setting the cache policy via the URL to allow us to
      // quickly test out different caching strategies.
      if (props.router.query.cachePolicy) {
        try {
          const cachePolicyQuery = Array.isArray(props.router.query.cachePolicy)
            ? props.router.query.cachePolicy[0]
            : props.router.query.cachePolicy;
          if (isCachePolicy(cachePolicyQuery)) {
            this.state.cachePolicy = cachePolicyQuery;
          }
        } catch (routerError) {
          console.error(routerError);
        }
      }
      if (props.router.query.page) {
        try {
          const pageQuery = Array.isArray(props.router.query.page)
            ? props.router.query.page[0]
            : props.router.query.page;
          this.state.page = parseInt(pageQuery, 10);
        } catch (routerError) {
          console.error(routerError);
        }
      }
      if (props.router.query.action) {
        try {
          const actionQuery = Array.isArray(props.router.query.action)
            ? props.router.query.action[0]
            : props.router.query.action;
          this.state.createModalOpen = actionQuery === 'create';
          delete props.router.query.action;
        } catch (routerError) {
          console.error(routerError);
        }
      }
      if (props.router.query.device) {
        try {
          const deviceQuery = Array.isArray(props.router.query.device)
            ? props.router.query.device[0]
            : props.router.query.device;
          this.state.device = String(deviceQuery);
          delete props.router.query.device;
        } catch (routerError) {
          console.error(routerError);
        }
      }
      const updatedQuery = { ...props.router.query };
      const url = format({ pathname: props.router.pathname, query: updatedQuery });
      void Router.replace(url, url, { shallow: true });

      this.updateSessionStorage({ pathname: props?.router?.pathname ?? '/lines', query: props?.router?.query ?? {} });
    } catch (error) {
      console.warn('\u00BB Something failed in overview-page constructor, hmm...');
    }
  }

  shouldComponentUpdate(nextProps: ExtendedProperties, nextState: State) {
    if (nextProps.timeAtLoad !== this.props.timeAtLoad) {
      return true;
    } else if (
      nextState.search?.length !== this.state.search?.length ||
      nextState.page !== this.state.page ||
      nextState.createModalOpen !== this.state.createModalOpen ||
      nextState.cardConf !== this.state.cardConf ||
      nextState.kpiOptions?.length !== this.state.kpiOptions?.length ||
      (!Object.keys(this.props.features.group).length && Object.keys(nextProps.features.group))
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, t, hasFeatureInAnyGroup, width, timeAtLoad, updateInterval } = this.props;
    const createLineFeature = hasFeatureInAnyGroup(['Lines.Line'], 'M');

    let nextLiveUpdate: Date | null = null;
    if (timeAtLoad && updateInterval) {
      nextLiveUpdate = new Date(timeAtLoad.getTime() + updateInterval);
    } else if (updateInterval === null) {
      nextLiveUpdate = null;
    }

    return (
      <Apollo.Query<Schema.LinesListSimpleQuery, Schema.LinesListSimpleQueryVariables>
        ssr={false}
        partialRefetch
        query={linesListSimple}
        fetchPolicy="cache-and-network"
        errorPolicy="all" // Necessary for returning partial data.
      >
        {({ loading, error, data }) => {
          const lines = data?.lines ?? [];
          const wrappedMessage = !loading && !error && !lines?.length ? this.getWrappedMessage() : undefined;

          // We only short-circuit the render if the query is loading or returned only an error (i.e. not an error + data).
          if ((loading && !data) || (error && !data)) {
            return (
              <>
                <LinesTopBar
                  search={this.state.search}
                  filteredLines={[]}
                  page={this.state.page}
                  pageSize={this.pageSize}
                  handlePageChange={this.handlePageChange}
                  handleSearchChange={this.handleSearchChange}
                  cardConf={this.state.cardConf}
                  updateConf={this.updateConf}
                  updateIntervalConf={this.updateIntervalConf}
                  updateRefreshIntervalConf={this.updateRefreshIntervalConf}
                  updateInterval={updateInterval}
                  nextLiveUpdate={nextLiveUpdate}
                />

                <LoadingOrError loading={loading} error={error} wrappedMessage={wrappedMessage} />
              </>
            );
          }
          const filteredLines = this.filterLines(lines);

          return (
            <>
              <LinesTopBar
                search={this.state.search}
                filteredLines={filteredLines}
                page={this.state.page}
                pageSize={this.pageSize}
                handlePageChange={this.handlePageChange}
                handleSearchChange={this.handleSearchChange}
                cardConf={this.state.cardConf}
                updateConf={this.updateConf}
                updateIntervalConf={this.updateIntervalConf}
                updateRefreshIntervalConf={this.updateRefreshIntervalConf}
                updateInterval={updateInterval}
                nextLiveUpdate={nextLiveUpdate}
              />
              <LoadingOrError loading={loading} error={error} data={data} wrappedMessage={wrappedMessage}>
                <Grid container spacing={1} alignItems="stretch" justifyContent="space-between">
                  <LineCards
                    filteredLines={filteredLines}
                    currentPage={this.state.page}
                    pageSize={this.pageSize}
                    timeAtLoad={timeAtLoad}
                    cardConf={this.state.cardConf}
                    cachePolicy={this.state.cachePolicy}
                  />
                  <Grid item container xs={12} direction="row-reverse">
                    <Grid item>
                      <Hidden mdUp>
                        <React.Suspense fallback={<div />}>
                          <Paginator
                            totalPages={Math.ceil(filteredLines.length / this.pageSize)}
                            currentPage={this.state.page}
                            onCurrentPageChange={this.handlePageChange}
                            totalCount={filteredLines.length}
                            pageSize={this.pageSize}
                          />
                        </React.Suspense>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </LoadingOrError>
              <CreateLineDialog
                open={this.state.createModalOpen}
                handleOpenClose={this.handleModalOpenClose(!this.state.createModalOpen)}
              />
              <StickyFab
                title={
                  createLineFeature
                    ? t(['line:createNewLine'], { defaultValue: 'Create new line' })
                    : t(['line:noPermissionToCreateLine'], {
                        defaultValue: "You don't have permission to create a new line",
                      })
                }
                disabled={!createLineFeature}
                onClick={this.handleModalOpenClose(true)}
              />
            </>
          );
        }}
      </Apollo.Query>
    );
  }

  private filterLines = (lines: Schema.LinesListSimpleQuery['lines']) => {
    const filteredLines = lines.filter((line) => {
      // If we haven't started searching yet, include all results.
      if (!this.state.search?.length) {
        return true;
      }
      // Filter out both results and remaining suggestions, based on the search filter.
      let foundInSearch: boolean = true;
      this.state.search.every((searchTerm) => {
        if (searchTerm && searchTerm.value && searchTerm.value.value) {
          foundInSearch =
            (line?.name ?? '').toLowerCase().includes(searchTerm.value.value.toLowerCase()) ||
            (line?.description ?? '').toLowerCase().includes(searchTerm.value.value.toLowerCase());
        }
        return Boolean(foundInSearch);
      });

      return foundInSearch;
    });
    // Sort the lines alphabetically, to always give a consistent view to the user.
    return filteredLines.sort((a, b) => a.name.localeCompare(b.name));
  };

  private getWrappedMessage = () => (
    // TODO: Only show this if you also have any devices. Else we should tell them to contact their local
    // administrator, like we do in devices.
    <>
      <Constants.LINE_ICON style={{ height: '100px', width: '100px' }} />
      <Typography variant="body2" className={this.props.classes.message}>
        {this.props.t(['line:noLinesYet'], { defaultValue: "You haven't created any lines yet!" })}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleModalOpenClose(true)}
        className={this.props.classes.lightBtn}
        aria-label={this.props.t(['shared:getStarted'], {
          defaultValue: 'Get started',
        })}
      >
        {this.props.t(['shared:getStarted'], { defaultValue: 'Get started' })}
      </Button>
    </>
  );

  private updateSessionStorage = (newRoute: UrlObject) => {
    // Update the session (tab specific) storage entry for navigation.
    if (typeof Storage !== 'undefined') {
      window.sessionStorage.setItem(
        Constants.SESSION_STORAGE_LINES_NAVIGATION_OVERVIEW_SEARCH_STATE_KEY,
        JSON.stringify({
          route: {
            pathname: newRoute.pathname,
            query: newRoute.query,
          },
        }),
      );
    }
  };

  private handlePageChange = async (page: number) => {
    const { pathname, query } = this.props.router;
    this.setState({ page });

    const updatedQuery = { ...query, page };
    const url = format({ pathname, query: updatedQuery });
    this.updateSessionStorage({ pathname, query: updatedQuery });

    await Router.replace(url, url, { shallow: true });
  };

  private handleSearchChange = async (val: SearchValue[]) => {
    const { pathname, query } = this.props.router;
    this.setState({ search: val, page: 0 });

    const updatedQuery = { ...query, search: JSON.stringify(val), page: 0 };
    const url = format({ pathname, query: updatedQuery });
    this.updateSessionStorage({ pathname, query: updatedQuery });

    await Router.replace(url, url, { shallow: true });
  };

  private handleModalOpenClose = (open: boolean) => () => this.setState({ createModalOpen: open });

  private updateConf = (key: string, check: boolean) => {
    this.setState({ cardConf: { ...this.state.cardConf, [key]: check } }, () => {
      // Labels can only ever be shown when stops are also enabled, so we force `showStops` to be enabled if it isn't already.
      if (key === 'showStopLabels' && check && this.state.cardConf?.showStops === false) {
        this.setState({ cardConf: { ...this.state.cardConf, showStops: true } });
      }
    });
  };

  private updateIntervalConf = (interval: TimeInterval) =>
    this.setState({ cardConf: { ...this.state.cardConf, timeInterval: interval } });

  private updateRefreshIntervalConf = (newInterval: number | null) => {
    this.setState({ cardConf: { ...this.state.cardConf, refreshInterval: newInterval } });
    this.props.changeUpdateInterval(newInterval);
  };
}
const enhance = compose<unknown, Properties>(
  withLine,
  withIAM,
  withRouter,
  withTranslation(['shared', 'line']),
  withStyles(styles),
  withWidth(),
);

export default enhance(LineCardPage as React.ComponentType<unknown>);

import { LinearProgress } from '@mui/material';
import { FunctionComponent, useContext, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ClockContext } from '@/hocs/with-clock';

const useStyles = makeStyles()(() => ({
  progress: {
    marginTop: '-4px',
  },
}));

interface Properties {
  nextLiveUpdate: Date | null;
  updateInterval: number | null;
  loading?: boolean;
  overrideContainerStyle?: { [key: string]: string | number };
  overrideProgressStyle?: { [key: string]: string | number };
}

const CountdownButton: FunctionComponent<Properties> = ({
  children,
  loading,
  overrideContainerStyle,
  overrideProgressStyle,
  nextLiveUpdate,
  updateInterval,
}) => {
  const { classes } = useStyles();
  const { currentTime, start } = useContext(ClockContext);

  useEffect(() => {
    start();
  }, []);

  const timeUntilNextLiveUpdate = nextLiveUpdate ? nextLiveUpdate.getTime() - currentTime.getTime() : 0;
  let liveProgress: number;
  if (updateInterval === null) {
    liveProgress = 0;
  } else if (timeUntilNextLiveUpdate <= 0) {
    liveProgress = 0;
  } else if (updateInterval <= timeUntilNextLiveUpdate) {
    liveProgress = 100;
  } else {
    liveProgress = 100 - ((updateInterval - timeUntilNextLiveUpdate) / updateInterval) * 100;
  }

  if (loading) {
    liveProgress = 100;
  }

  return (
    <div style={overrideContainerStyle}>
      {children}
      {nextLiveUpdate !== null && (
        <LinearProgress
          className={classes.progress}
          variant={loading ? 'query' : 'determinate'}
          value={liveProgress}
          style={overrideProgressStyle}
        />
      )}
    </div>
  );
};

export default CountdownButton;

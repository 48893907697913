import Link from '@blackbird/ui-base/link';
import { MoreVert as MoreVertIcon, OpenInBrowser, SettingsSharp } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import PinItem from '@/components/pin-item';
import * as Constants from '@/constants';
import { WithIAM, withIAM } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import * as Types from '@/types';

const styles = (theme: Theme) => ({
  menuItemStyles: {
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuItemIcon: {
    minWidth: theme.spacing(4),
  },
});

interface Properties {
  lineId: string;
  mainSensor?: Types.DeepPartial<Schema.LineOverviewWithKpiQuery['line']['mainSensor']>;
  openDialog: () => void;
}

interface State {
  menuAnchorEl: HTMLElement | null;
}

class LineOverviewCardMenu extends React.Component<
  Properties & WithTranslation & WithStyles<typeof styles> & WithIAM,
  State
> {
  readonly state: State = {
    menuAnchorEl: null,
  };

  private buttons = [
    {
      label: this.props.t(['shared:registerStops'], { defaultValue: 'Register Stops' }),
      uri: 'register-stops',
    },
    {
      label: this.props.t(['shared:batches'], { defaultValue: 'Batches' }).titleCase,
      uri: 'batches',
    },
    {
      label: this.props.t(['shared:analytics'], { defaultValue: 'Analytics' }),
      uri: 'analytics',
    },
    {
      label: this.props.t(['shared:oee'], { defaultValue: 'OEE' }),
      uri: 'oee',
    },
    {
      label: this.props.t(['shared:trends'], { defaultValue: 'Trends' }),
      uri: 'trends',
    },
  ];

  render() {
    const { t, lineId, classes, mainSensor } = this.props;
    const mainSensorUnitLabel = mainSensor?.config?.sensorUnit?.label ?? '';
    const openMenu = Boolean(this.state.menuAnchorEl);

    const canEditLines = this.props.hasFeatureInAnyGroup(['Lines.Line'], 'M');

    return (
      <div style={{ flex: '0 0 auto' }}>
        <IconButton
          aria-label="More"
          aria-owns={openMenu ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.menuAnchorEl}
          open={openMenu}
          onClose={this.handleMenuClose}
          PaperProps={{ style: {} }}
        >
          {this.buttons.map((btn) => (
            <Link key={btn.uri} href={{ pathname: '/line', query: { lineId, tab: btn.uri } }}>
              <MenuItem
                key={`${btn.uri}-Menu`}
                selected={false}
                onClick={this.onMenuItemClick}
                className={classes.menuItemStyles}
              >
                <Typography variant="inherit" noWrap>
                  {btn.label}
                </Typography>
              </MenuItem>
            </Link>
          ))}
          <Divider variant="middle" />
          {!canEditLines ? null : (
            <MenuItem
              key={'lineSettingsMenu'}
              selected={false}
              onClick={this.onLineSettingsClick}
              className={classes.menuItemStyles}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SettingsSharp />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {t(['line:lineSettings'], { defaultValue: 'Line settings' })}
              </Typography>
            </MenuItem>
          )}
          <Link key={'lineSchedule'} href={{ pathname: '/line', query: { lineId, tab: 'schedule' } }}>
            <MenuItem
              key={'lineScheduleMenu'}
              selected={false}
              onClick={this.onMenuItemClick}
              className={classes.menuItemStyles}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <Constants.SCHEDULE_ICON />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {t(['shared:schedule'], { defaultValue: 'Schedule' })}
              </Typography>
            </MenuItem>
          </Link>
          <Link href={{ pathname: '/dashboard/batch', query: { lineId, layout: 'compact' } }}>
            <MenuItem
              key={'batchDashboardMenu'}
              selected={false}
              onClick={this.onMenuItemClick}
              className={classes.menuItemStyles}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <OpenInBrowser />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {t(['shared:batchDashboard'], { defaultValue: 'Batch Dashboard' })}
              </Typography>
            </MenuItem>
          </Link>
          <Link
            key={'shiftDashboard'}
            href={{ pathname: '/dashboard/shift', query: { lineId, layout: 'compact', mainSensorUnitLabel } }}
          >
            <MenuItem
              key={'shiftDashboardMenu'}
              selected={false}
              onClick={this.onMenuItemClick}
              className={classes.menuItemStyles}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <OpenInBrowser />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {t(['shared:shiftDashboard'], { defaultValue: 'Shift Dashboard' })}
              </Typography>
            </MenuItem>
          </Link>
          <Divider variant="middle" />
          <PinItem identifier={lineId} storage={'LINES'} />
        </Menu>
      </div>
    );
  }

  private handleMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  private handleMenuClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ menuAnchorEl: null });
  };

  private onLineSettingsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ menuAnchorEl: null });
    this.props.openDialog();
  };

  private onMenuItemClick = () => this.setState({ menuAnchorEl: null });
}

const enhance = compose<unknown, Properties>(
  withTranslation(['shared', 'line']),
  withStyles(styles, { withTheme: true }),
  withIAM,
);

export default enhance(LineOverviewCardMenu as React.ComponentType<unknown>);

import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { subDays } from 'date-fns';
import * as Schema from 'generated/graphql/schema';
import * as i18next from 'i18next';

import * as Types from '@/types';

export type ExtendedAppointmentModel = AppointmentModel & {
  originalShift: Schema.Shift;
};

// CC-BY-CA: https://stackoverflow.com/a/6117889
export const getYearWeek = (d: Date): Omit<Schema.ScheduleTime, '__typename'> => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  // Return array of year and week number
  return { year: d.getUTCFullYear(), week: weekNo };
};

/**
 * Extract the schedule that is valid in the date you request. If not supplied
 * an array of schedules, it will return the schedule immediately.
 */
export const extractCurrentSchedule = (
  schedules: Schema.Schedule[] | Schema.Schedule,
  date: Date,
): Schema.Schedule | undefined => {
  const target = getYearWeek(date);
  if (!Array.isArray(schedules)) {
    return schedules;
  }
  // If we only have one schedule (usually the case from our request), we take it directly.
  if (schedules.length === 1) {
    return schedules[0];
  } else if (schedules.length > 1) {
    // If we are given more schedules, find the one that is the currently active one, based on the `validFrom` periods.
    let activeSchedule: Schema.Schedule | undefined;

    schedules.map((schedule) => {
      if (schedule.validFrom.year <= target.year && schedule.validFrom.week <= target.week) {
        // If no schedule has been set, just set the initial one.
        if (!activeSchedule) {
          activeSchedule = schedule;
          return;
        }
        // Check if the schedule's validFrom period is closer to our target, than the current `activeSchedule`.
        const scheduleYearDiff = target.year - schedule.validFrom.year;
        const activeScheduleYearDiff = target.year - activeSchedule.validFrom.year;
        const scheduleWeekDiff = target.week - schedule.validFrom.week;
        const activeScheduleWeekDiff = target.week - activeSchedule.validFrom.week;
        if (scheduleYearDiff <= activeScheduleYearDiff && scheduleWeekDiff < activeScheduleWeekDiff) {
          activeSchedule = schedule;
          return;
        }
      }
    });

    return activeSchedule;
  }

  return undefined;
};

/**
 * Convert a `DayOfWeek` enum to a string.
 */
export const getDayStringOfEnum = (
  t: i18next.TFunction,
  dayOfWeek: Schema.DayOfWeek | 0 | 1 | 2 | 3 | 4 | 5 | 6,
): string => {
  switch (dayOfWeek) {
    case 0:
    case Schema.DayOfWeek.SUNDAY:
      return t(['shared:sunday'], { defaultValue: 'Sunday' });
    case 1:
    case Schema.DayOfWeek.MONDAY:
      return t(['shared:monday'], { defaultValue: 'Monday' });
    case 2:
    case Schema.DayOfWeek.TUESDAY:
      return t(['shared:tuesday'], { defaultValue: 'Tuesday' });
    case 3:
    case Schema.DayOfWeek.WEDNESDAY:
      return t(['shared:wednesday'], { defaultValue: 'Wednesday' });
    case 4:
    case Schema.DayOfWeek.THURSDAY:
      return t(['shared:thursday'], { defaultValue: 'Thursday' });
    case 5:
    case Schema.DayOfWeek.FRIDAY:
      return t(['shared:friday'], { defaultValue: 'Friday' });
    case 6:
    case Schema.DayOfWeek.SATURDAY:
      return t(['shared:saturday'], { defaultValue: 'Saturday' });
    default:
      Types.assertUnreachable(dayOfWeek);
      return t(['shared:monday'], { defaultValue: 'Monday' });
  }
};

/**
 * Convert a day 'number' into a `DayOfWeek` enum.
 */
export const getDayEnumFromDay = (dayOfWeek: number): Schema.DayOfWeek => {
  switch (dayOfWeek) {
    case 0:
      return Schema.DayOfWeek.SUNDAY;
    case 1:
      return Schema.DayOfWeek.MONDAY;
    case 2:
      return Schema.DayOfWeek.TUESDAY;
    case 3:
      return Schema.DayOfWeek.WEDNESDAY;
    case 4:
      return Schema.DayOfWeek.THURSDAY;
    case 5:
      return Schema.DayOfWeek.FRIDAY;
    case 6:
      return Schema.DayOfWeek.SATURDAY;
    default:
      return Schema.DayOfWeek.MONDAY;
  }
};

/**
 * Convert a `DayOfWeek` enum to a sortable number, rotating it based on `startDayOfWeek`.
 */
export const getDaySortNumber = (
  dayOfWeek: Schema.DayOfWeek,
  startDayOfWeek: Schema.DayOfWeek = Schema.DayOfWeek.SUNDAY,
): number => {
  // Figure out what the start day is so we can rotate by this number.
  const foundStartDay = getDayNumberOfEnum(startDayOfWeek);
  let sortNumber = 0;
  switch (dayOfWeek) {
    case Schema.DayOfWeek.SUNDAY:
      sortNumber = 0 < foundStartDay ? 0 + 7 : 0;
      break;
    case Schema.DayOfWeek.MONDAY:
      sortNumber = 1 < foundStartDay ? 1 + 7 : 1;
      break;
    case Schema.DayOfWeek.TUESDAY:
      sortNumber = 2 < foundStartDay ? 2 + 7 : 2;
      break;
    case Schema.DayOfWeek.WEDNESDAY:
      sortNumber = 3 < foundStartDay ? 3 + 7 : 3;
      break;
    case Schema.DayOfWeek.THURSDAY:
      sortNumber = 4 < foundStartDay ? 4 + 7 : 4;
      break;
    case Schema.DayOfWeek.FRIDAY:
      sortNumber = 5 < foundStartDay ? 5 + 7 : 5;
      break;
    case Schema.DayOfWeek.SATURDAY:
      sortNumber = 6 < foundStartDay ? 6 + 7 : 6;
      break;
    default:
      Types.assertUnreachable(dayOfWeek);
      sortNumber = 1;
      break;
  }
  return sortNumber;
};

/**
 * Convert a `DayOfWeek` enum to a number. We default to Monday (i.e. 1).
 */
export const getDayNumberOfEnum = (dayOfWeek: Schema.DayOfWeek): number => {
  switch (dayOfWeek) {
    case Schema.DayOfWeek.SUNDAY:
      return 0;
    case Schema.DayOfWeek.MONDAY:
      return 1;
    case Schema.DayOfWeek.TUESDAY:
      return 2;
    case Schema.DayOfWeek.WEDNESDAY:
      return 3;
    case Schema.DayOfWeek.THURSDAY:
      return 4;
    case Schema.DayOfWeek.FRIDAY:
      return 5;
    case Schema.DayOfWeek.SATURDAY:
      return 6;
    default:
      Types.assertUnreachable(dayOfWeek);
      return 1;
  }
};

/**
 * Convert a schedule shift element into a appointment element needed for the calendar widget.
 *
 * This part gets a little hairy. We need to convert the generic "Monday 8 - 16" into a specific
 * date on the calendar, based on the dates we are shown. Furthermore, these dates can change, depending
 * on the start day of the week that the user has configured.
 *
 * We first figure out if the calendar if the start day of the calendar is after our current date, which
 * will mean that the week view is shifted one week back.
 *
 * Then we take the day of the shift event, e.g. Monday, and subtract the start day, e.g. Tuesday. If
 * this day is lower than 0, we know that we are on a date before the start date, and we need to add 7
 * days to the result of this, because we previously accounted for the shift in the week view.
 *
 * Finally, we can get the dates from the current date + the shift in week view + the shift from start
 * date.
 */
export const convertGenericShiftTimeToDatePair = <
  T extends {
    from: Omit<Schema.ShiftTime, '__typename'>;
    to: Omit<Schema.ShiftTime, '__typename'>;
  },
>(
  timeRange: T,
  date: Date,
  startDay: Schema.DayOfWeek,
): { from: Date; to: Date } => {
  const { from, to } = timeRange;

  const dayOfStartDay = getDayNumberOfEnum(startDay);
  const dayOfShiftStart = getDayNumberOfEnum(from.day);
  let dayOfShiftEnd = getDayNumberOfEnum(to.day);
  const currentDayInWeek = date.getDay();

  if (dayOfShiftEnd < dayOfShiftStart) {
    dayOfShiftEnd += 7;
  }

  // If the start day is before the current day of the week, we must shift our days back.
  let shiftToLastWeek = 0;
  if (currentDayInWeek < dayOfStartDay) {
    // We shift it back a week, and then add the days in that week that has already passed.
    shiftToLastWeek = -7 + (dayOfStartDay - currentDayInWeek);
  } else {
    shiftToLastWeek = dayOfStartDay - currentDayInWeek;
  }

  // Shift the day of the shift based on the start day of the schedule.
  let shiftForStartDate = dayOfShiftStart - dayOfStartDay;
  if (shiftForStartDate < 0) {
    // If it's below 0, that's because it wrapped around, so we subtract the shift from 7.
    shiftForStartDate = 7 + shiftForStartDate;
  }

  // Finally, we create a new date from this shift in days.
  const shiftedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  shiftedDate.setDate(shiftedDate.getDate() + shiftToLastWeek + shiftForStartDate);

  const wrapShift = dayOfShiftEnd < dayOfShiftStart;

  const shiftShift = wrapShift ? dayOfShiftStart - 1 : dayOfShiftEnd - dayOfShiftStart;

  return {
    from: new Date(shiftedDate.getFullYear(), shiftedDate.getMonth(), shiftedDate.getDate(), from.hour, from.minute),
    to: new Date(
      shiftedDate.getFullYear(),
      shiftedDate.getMonth(),
      shiftedDate.getDate() + shiftShift,
      to.hour,
      to.minute,
    ),
  };
};

/**
 * Convert a shift into an appointment, for use with our DX Scheduler component. An
 * appointment looks something like (as a minimum):
 * ```
{
  title: 'Website Re-Design Plan',
  startDate: new Date(2019, 4, 15, 9, 30),
  endDate: new Date(2019, 4, 15, 11, 30),
  id: 0,
  location: 'Room 1',
}
```
 */
export const mkAppointmentsFromShifts = (
  shifts: Schema.Shift[],
  date: Date,
  startDay: Schema.DayOfWeek,
): ExtendedAppointmentModel[] => {
  const { from: scheduleStart, to: scheduleEnd } = convertGenericShiftTimeToDatePair(
    {
      from: { day: startDay, hour: 0, minute: 0 },
      to: {
        day: startDay === Schema.DayOfWeek.SUNDAY ? Schema.DayOfWeek.SATURDAY : Schema.DayOfWeek.SUNDAY,
        hour: 23,
        minute: 59,
      },
    },
    date,
    startDay,
  );

  return shifts.reduce((shifts, shift) => {
    // All this hackery is necessary because the devexpress framework does not support "appointments" that are longer
    // than `24 hours - 1ms`.
    // Shifts rotate on a week boundary, so we determine the chunks based off of the duration to consume - that is, if
    // the shift spans more than 24 hours, we split it into consecutive chunks.
    let { from: startDate, to: endDate } = convertGenericShiftTimeToDatePair(shift.timeRange, date, startDay);

    // If DST starts during the span in the following week, make sure we use the end timezone rather than the following
    // week's when we circle back to the beginning.
    const endDateOffset =
      scheduleEnd.getTime() < endDate.getTime() ? scheduleEnd.getTimezoneOffset() : endDate.getTimezoneOffset();

    // We shift to local time to utilize modulus rings targetting 24 hours. This should also reveal any DST
    // changes. However, we use the calendar boundary if end spans into the following week.
    const shiftedStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * (1).minutes);
    const shiftedEndDate = new Date(endDate.getTime() - endDateOffset * (1).minutes);

    let startSplit = shiftedStartDate.getTime();
    let durationToConsume = shiftedEndDate.getTime() - shiftedStartDate.getTime();

    while (0 < durationToConsume) {
      // Subtract one to avoid extending into the following day - it's added back when subtracting the duration.
      const dayEnd = Math.ceil((startSplit + 1) / (24).hours) * (24).hours - 1;

      const startTimeOffset = new Date(startSplit).getTimezoneOffset() * (1).minutes;

      const partStartDate = new Date(startSplit + startTimeOffset);

      const timeTillMidnight = dayEnd - startSplit;
      const partEndDate = new Date(partStartDate.getTime() + Math.min(durationToConsume, timeTillMidnight));

      shifts.push({
        title: shift.name,
        id: shift.id,
        startDate: partStartDate,
        endDate: partEndDate,
        originalShift: shift,
      });

      durationToConsume -= partEndDate.getTime() - partStartDate.getTime() + 1;

      // We don't need to shift the scheduleStart, since it's cancelled out after the rotation.
      startSplit = scheduleStart.getTime() + ((startSplit - scheduleStart.getTime() + timeTillMidnight + 1) % (7).days);
    }

    return shifts;
  }, [] as ExtendedAppointmentModel[]);
};

/**
 * We remap the whole schedule, to avoid all `__typename` fields.
 */
export const unrollSchedule = (
  schedule: {
    lineId: string;
    validFrom: Omit<Schema.ScheduleTime, '__typename'>;
  } & Partial<Schema.Schedule>,
): Schema.MutationUpsertScheduleArgs => ({
  lineId: schedule?.lineId,
  validFrom: {
    year: schedule?.validFrom.year,
    week: schedule?.validFrom.week,
  },
  isExceptionalWeek: schedule?.isExceptionalWeek ?? false,
  shifts: (schedule?.shifts ?? []).map((shift) => ({
    id: shift.id,
    name: shift.name,
    description: shift.description,
    targets: {
      oee: shift?.targets?.oee,
      oee2: shift?.targets?.oee2,
      oee3: shift?.targets?.oee3,
      tcu: shift?.targets?.tcu,
      produced: shift?.targets?.produced,
      numberOfBatches: shift?.targets?.numberOfBatches,
    },
    timeRange: {
      from: {
        day: shift?.timeRange?.from?.day,
        hour: shift?.timeRange?.from?.hour,
        minute: shift?.timeRange?.from?.minute,
      },
      to: {
        day: shift?.timeRange?.to?.day,
        hour: shift?.timeRange?.to?.hour,
        minute: shift?.timeRange?.to?.minute,
      },
    },
  })),
});

export const getShiftFromShifts = <T extends Pick<Schema.Shift, 'timeRange'>>(
  shifts: T[],
  date: Date,
  startDay: Schema.DayOfWeek,
  options?: { target: 'previous' | 'current' | 'next' },
):
  | {
      shift: T;
      startDate: Date;
      endDate: Date;
    }
  | undefined => {
  const target = options?.target || 'current';

  // Bipolar offset, i.e.: -1, 0, 1
  const indexOffset = +(target === 'previous') || -(target === 'next');

  const sortedShifts = [...shifts].sort((a, b) => {
    const { to: dateA } = convertGenericShiftTimeToDatePair(a.timeRange, date, startDay);
    const { to: dateB } = convertGenericShiftTimeToDatePair(b.timeRange, date, startDay);
    return dateA.getTime() - dateB.getTime();
  });

  const restructuredShifts = sortedShifts.map((shift) => {
    const { from: startDate, to: endDate } = convertGenericShiftTimeToDatePair(shift.timeRange, date, startDay);

    return { shift, startDate, endDate };
  });

  const [lastShift] = restructuredShifts.slice(restructuredShifts.length - 1);

  if (!lastShift) {
    return;
  }

  if ((lastShift.endDate.getDay() || 7) < (lastShift.startDate.getDay() || 7)) {
    restructuredShifts.unshift({
      shift: lastShift.shift,
      startDate: subDays(lastShift.startDate, 7),
      endDate: subDays(lastShift.endDate, 7),
    });
  }

  const index = restructuredShifts.reverse().findIndex(({ startDate }) => {
    return startDate <= date;
  });

  return restructuredShifts[index + indexOffset];
};

const getWeekdayNumberSane = (dayOfWeek: Schema.DayOfWeek): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  switch (dayOfWeek) {
    case 'MONDAY':
      return 0;
    case 'TUESDAY':
      return 1;
    case 'WEDNESDAY':
      return 2;
    case 'THURSDAY':
      return 3;
    case 'FRIDAY':
      return 4;
    case 'SATURDAY':
      return 5;
    case 'SUNDAY':
      return 6;
    default:
      return 0;
  }
};

export const shiftDuration = (timeRange: Types.OmitRecursively<Schema.ShiftTimeRange, '__typename'>) => {
  const {
    from: { day: fromDayEnum, hour: fromHour, minute: fromMinute },
    to: { day: toDayEnum, hour: toHour, minute: toMinute },
  } = timeRange;

  // NOTE: Shift it by one day, just to make sure we don't end up at a zero-day
  // (1970-01-00)
  const fromDay = getWeekdayNumberSane(fromDayEnum) + 1;
  let toDay = getWeekdayNumberSane(toDayEnum) + 1;

  if (toDay < fromDay) {
    toDay += 7;
  }

  const fromTimestamp = new Date(
    [
      '1970-01-',
      [fromDay, fromHour, fromMinute]
        .map((part) => part.toLocaleString(undefined, { minimumIntegerDigits: 2 }))
        .join(':')
        .replace(':', 'T'),
      ':00.000Z',
    ].join(''),
  ).getTime();

  const toTimestamp = new Date(
    [
      '1970-01-',
      [toDay, toHour, toMinute]
        .map((part) => part.toLocaleString(undefined, { minimumIntegerDigits: 2 }))
        .join(':')
        .replace(':', 'T'),
      ':00.000Z',
    ].join(''),
  ).getTime();

  return toTimestamp - fromTimestamp;
};

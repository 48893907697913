import { Card, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = () => ({
  progressContainer: {
    height: '2em',
    width: '100%',
    position: 'relative',
    border: '1px solid black',
  },
  smallProgressContainer: {
    height: '2em',
    width: '100%',
    position: 'relative',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  progressText: {
    position: 'absolute',
    top: '50%',
    marginTop: '-1em',
    width: '100%',
    textAlign: 'center',
  },
  smallProgressText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallInlineProgressText: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
  },
  progressBarRed: {
    '&:after': {
      backgroundColor: '#ff5b5b',
      filter: 'invert(1)',
    },
  },
  progressBarGreen: {
    '&:after': {
      backgroundColor: '#43a047',
      filter: 'invert(1)',
      willChange: 'opacity',
    },
  },
  progressBar: {
    height: '100%',
    position: 'relative',
    width: '0%',
    transition: '1s width',
    '&:before, &:after': {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: 'absolute',
      content: '""',
    },
    '&:before': {
      zIndex: 1,
      pointerEvents: 'none',
      mixBlendMode: 'difference',
      backgroundColor: 'white',
    },
  },
  // Chrome, Safari, new Chrome-based Edge Browser and Firefox
  '@supports (-ms-ime-align:auto)': {
    progressBar: {
      '&:before': {
        // Because mixBlendMode does not work in Edge, we need to disable the background color for Edge only.
        backgroundColor: 'transparent',
      },
    },
  },
  batchText: {
    fontSize: 20,
    marginBottom: '12px',
    textAlign: 'center',
    width: '100%',
  },
  smallBatchText: {
    fontSize: '0.875rem',
    marginBottom: '0',
    textAlign: 'center',
    width: '100%',
  },
});

interface Properties {
  left: string | number;
  right: string | number;
  inlineTitle?: string;
  completePercentage: number;
  small?: boolean;
  red?: boolean;
}

interface State {}
class Progress extends React.Component<Properties & WithStyles<typeof styles>, State> {
  public render() {
    const { classes, left, right, inlineTitle, completePercentage, small, red = false } = this.props;
    return (
      <Card elevation={1}>
        <div className={small ? classes.smallProgressContainer : classes.progressContainer}>
          <div
            style={{
              width: `${Math.min(100, completePercentage)}%`,
            }}
            className={clsx(classes.progressBar, red ? classes.progressBarRed : classes.progressBarGreen)}
          />

          <span className={small ? classes.smallProgressText : classes.progressText}>
            {inlineTitle ? (
              <Typography variant="body2" className={small ? classes.smallBatchText : classes.batchText} noWrap={true}>
                {inlineTitle + ': '}
                {left} {left !== '' ? '/' : ''} {right}{' '}
              </Typography>
            ) : (
              <Typography variant="body2" className={small ? classes.smallBatchText : classes.batchText} noWrap={true}>
                {' '}
                {left} {left !== '' ? '/' : ''} {right}{' '}
              </Typography>
            )}
          </span>
        </div>
      </Card>
    );
  }
}
const enhance = compose<unknown, Properties>(withStyles(styles));
export default enhance(Progress as React.ComponentType<unknown>);

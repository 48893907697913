import { Card, CardContent, Grid, Theme } from '@mui/material';
import React from 'react';
import { compose } from 'recompose';

import SearchBar, { SearchValue } from '@/components/bars/search-bar';
import Paginator from '@/components/paginator';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  hideOnSMDown: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideOnMDup: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});
interface Properties {
  search: SearchValue[];
  suggestions: SearchValue[];
  totalCount: number;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handleSearchChange: (val: SearchValue[]) => void;
}

interface State {}

class OverviewTopBar extends React.Component<Properties & WithStyles<typeof styles>, State> {
  public render() {
    const { suggestions, search, children, totalCount, page, pageSize, handleSearchChange, handlePageChange, classes } =
      this.props;

    return (
      <Card elevation={1} style={{ overflow: 'visible', marginBottom: 8 }}>
        <CardContent style={{ paddingBottom: '16px' }}>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'nowrap' }}>
            <SearchBar suggestions={suggestions} value={search} handleChange={handleSearchChange} />

            <div className={classes.hideOnSMDown}>
              <React.Suspense fallback={<div />}>
                <Paginator
                  totalPages={Math.ceil(totalCount / pageSize)}
                  currentPage={page}
                  onCurrentPageChange={handlePageChange}
                  totalCount={totalCount}
                  pageSize={pageSize}
                />
              </React.Suspense>
              {children}
            </div>
          </div>
          <div className={classes.hideOnMDup}>
            <Grid justifyContent="space-between" container>
              <Grid item>
                <React.Suspense fallback={<div />}>
                  <Paginator
                    totalPages={Math.ceil(totalCount / pageSize)}
                    currentPage={page}
                    onCurrentPageChange={handlePageChange}
                    totalCount={totalCount}
                    pageSize={pageSize}
                  />
                </React.Suspense>
              </Grid>
              <Grid item>{children}</Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    );
  }
}

const enhance = compose<unknown, Properties>(withStyles(styles, { withTheme: true }));

export default enhance(OverviewTopBar as React.ComponentType<unknown>);


    var doc = JSON.parse('{"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Batch"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Batch"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"batchId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"batchNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"manualScrap"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plannedStart"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"actualStart"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"actualStop"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"comment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lineId"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":144}}');
    doc.loc.source = JSON.parse('{"body":"fragment Batch on Batch {\\n  batchId\\n  batchNumber\\n  state\\n  amount\\n  manualScrap\\n  plannedStart\\n  actualStart\\n  actualStop\\n  comment\\n  lineId\\n}\\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}}');
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

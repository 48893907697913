
    var doc = JSON.parse('{"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Product"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"itemNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lineId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"validatedLineSpeed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expectedAverageSpeed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dataMultiplier"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"comment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parameters"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":181}}');
    doc.loc.source = JSON.parse('{"body":"fragment Product on Product {\\n  productId\\n  name\\n  itemNumber\\n  lineId\\n  validatedLineSpeed\\n  expectedAverageSpeed\\n  dataMultiplier\\n  comment\\n  parameters {\\n    key\\n    value\\n  }\\n}\\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}}');
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

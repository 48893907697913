import { Card, CardContent } from '@mui/material';
import React from 'react';
import { compose } from 'recompose';

import { WithUtils, withUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = () => ({
  small: {
    height: '2em',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Properties {
  className?: string;
  backgroundColor?: string;
  small?: boolean;
  cardContentClass?: string;
}

interface State {}
class Box extends React.Component<Properties & WithUtils & WithStyles<typeof styles>, State> {
  public render() {
    const { classes, className, small, backgroundColor, cardContentClass = '' } = this.props;
    return (
      <Card
        elevation={1}
        className={className}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
        }}
      >
        {small ? (
          <div className={classes.small}>{this.props.children}</div>
        ) : (
          <CardContent className={cardContentClass} style={{ display: 'flex', justifyContent: 'center' }}>
            {this.props.children}
          </CardContent>
        )}
      </Card>
    );
  }
}
const enhance = compose<unknown, Properties>(withUtils, withStyles(styles));
export default enhance(Box as React.ComponentType<unknown>);

import { calculateStartPage, firstRowOnPage, lastRowOnPage } from '@devexpress/dx-grid-core';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  pagination: {
    alignItems: 'center',
    marginLeft: '1em',
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  rowsLabel: {
    whiteSpace: 'nowrap',
    ...theme.typography.caption,
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingRight: '0',
    },
    lineHeight: theme.spacing(5),
  },
  button: {
    color: 'inherit',
    minWidth: theme.spacing(2),
    height: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  activeButton: {
    fontWeight: 'bold',
    cursor: 'default',
  },
  arrowButton: {
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : undefined,
  },
});

interface PageButtonProperties {
  text: string;
  isActive?: boolean;
  isDisabled?: boolean;
  classes: WithStyles<typeof styles>['classes'];
  onClick?: () => void;
}

const PageButton = (args: PageButtonProperties) => {
  const { text, isActive, isDisabled, classes, onClick } = args;
  const buttonClasses = clsx({
    [classes.button]: true,
    [classes.activeButton]: isActive,
  });

  return (
    <Button className={buttonClasses} disabled={isDisabled} onClick={onClick} disableRipple={isActive}>
      {text}
    </Button>
  );
};

const ellipsisSymbol = '\u2026';

interface Properties {
  totalPages: number;
  currentPage: number;
  onCurrentPageChange: (desiredPage: number) => void;
  totalCount: number;
  pageSize: number;
}

interface State {}
class Paginator extends React.Component<Properties & WithStyles<typeof styles> & WithTranslation, State> {
  render() {
    const { totalPages, totalCount, pageSize, currentPage, classes, t } = this.props;
    const from = firstRowOnPage(currentPage, pageSize, totalCount);
    const to = lastRowOnPage(currentPage, pageSize, totalCount);
    return (
      <div className={classes.pagination}>
        <span className={classes.rowsLabel}>{`${from}${from < to ? `-${to}` : ''} ${t(['shared:pageOf'], {
          defaultValue: 'of',
        })} ${totalCount}`}</span>
        <Grid container alignItems="center">
          <IconButton
            className={classes.arrowButton}
            disabled={currentPage === 0}
            onClick={this.previousPage}
            size="large"
          >
            <ChevronLeft />
          </IconButton>
          {this.renderPageButtons()}
          <IconButton
            className={classes.arrowButton}
            disabled={currentPage === totalPages - 1 || totalCount === 0}
            onClick={this.nextPage}
            size="large"
          >
            <ChevronRight />
          </IconButton>
        </Grid>
      </div>
    );
  }
  private setPage = (desiredPage: number) => () => this.props.onCurrentPageChange(desiredPage);
  private renderPageButtons = () => {
    const { totalPages, currentPage, classes } = this.props;
    const pageButtons: JSX.Element[] = [];
    const maxButtonCount = 3;
    let startPage = 1;
    let endPage = totalPages || 1;
    if (maxButtonCount < totalPages) {
      startPage = calculateStartPage(currentPage + 1, maxButtonCount, totalPages);
      endPage = startPage + maxButtonCount - 1;
    }
    if (startPage > 1) {
      pageButtons.push(<PageButton key={1} text={String(1)} classes={classes} onClick={this.setPage(0)} />);
      if (startPage > 2) {
        pageButtons.push(<PageButton key="ellipsisStart" text={ellipsisSymbol} classes={classes} isDisabled />);
      }
    }
    for (let page = startPage; page <= endPage; page += 1) {
      pageButtons.push(
        <PageButton
          key={page}
          text={String(page)}
          isActive={page === currentPage + 1}
          classes={classes}
          onClick={this.setPage(page - 1)}
          isDisabled={startPage === endPage}
        />,
      );
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageButtons.push(<PageButton key="ellipsisEnd" text={ellipsisSymbol} classes={classes} isDisabled />);
      }
      pageButtons.push(
        <PageButton
          key={totalPages}
          text={String(totalPages)}
          classes={classes}
          onClick={this.setPage(totalPages - 1)}
        />,
      );
    }
    return pageButtons;
  };
  private previousPage = () => {
    const { currentPage, onCurrentPageChange } = this.props;
    return currentPage > 0 && onCurrentPageChange(currentPage - 1);
  };
  private nextPage = () => {
    const { totalPages, currentPage, onCurrentPageChange } = this.props;
    return currentPage < totalPages - 1 && onCurrentPageChange(currentPage + 1);
  };
}
const enhance = compose<unknown, Properties>(withTranslation(['shared']), withStyles(styles, { name: 'Paginator' }));
export default enhance(Paginator as React.ComponentType<unknown>);

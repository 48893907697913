import * as Schema from 'generated/graphql/schema';

export * from './types';

export type { GraphQLContext } from '@blackbird/graphql-service';

// FIXME: Use Schema.Time once we've parsed Date scalar inputs from the API as
// dates (through an Apollo link -
// e.g. https://github.com/eturino/apollo-link-scalars).
export interface TimeDate {
  from?: Date | null;
  to?: Date | null;
}

// NOTE: This preserves the "optionality" of the input fields, e.g. from and to,
// but maps the rhs type to string.
export function toISOString<TIME extends TimeDate>(time: TIME) {
  const mappedRange = {} as { [P in keyof TIME]: string };

  if (time.from) {
    mappedRange.from = time.from.toISOString();
  }

  if (time.to) {
    mappedRange.to = time.to.toISOString();
  }

  return mappedRange;
}

export interface EnumTypes {
  analogInputRanges: NonNullable<Schema.LineQuery['analogInputRanges']>['analogInputRanges'];
  units: NonNullable<Schema.LineQuery['units']>['units'];
  sensorTypes: NonNullable<Schema.LineQuery['sensorTypes']>['sensorTypes'];
}

export type DeepNonNullable<T> = { [P in keyof T]-?: NonNullable<T[P]> } & NonNullable<T>;
type OmitDistributive<T, K extends string> = T extends any ? (T extends object ? Id<OmitRecursively<T, K>> : T) : never;
type Id<T> = {} & { [P in keyof T]: T[P] };
export type OmitRecursively<T extends any, K extends string> = Omit<{ [P in keyof T]: OmitDistributive<T[P], K> }, K>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type EntityTuple<P> = Array<[Schema.EntityType, (params: P) => JSX.Element]>;

export type SelectedDatesType = Array<{ date: Date; selected: boolean }>;

export type DatesArray = Array<{ date: Date }>;

export type MergedChangeoverStopAggregatedPart = Omit<Schema.ChangeoverStop, 'comment' | 'initials' | 'registeredTime'>;

export type MergedChangeoverStopJoinedPart = {
  durationList: Array<null | Schema.ChangeoverStop['duration']>;
  comment: Array<null | Schema.ChangeoverStop['comment']>;
  initials: Array<null | Schema.ChangeoverStop['initials']>;
  registeredTime: Array<null | Schema.ChangeoverStop['registeredTime']>;
  timeRangeList: Array<null | Schema.ChangeoverStop['timeRange']>;
};

// Certain fields of the changeover stop can hold a value per stop that has been merged, while others can be aggregated.
export type MergedChangeoverStop = MergedChangeoverStopAggregatedPart & MergedChangeoverStopJoinedPart;

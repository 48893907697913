import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import { Options as HighchartsOptions } from 'highcharts';
import * as i18next from 'i18next';

import { DateTimeLabelFormatBasedOnBrowserLocale } from '@/helpers/helper-functions';
import { determineLabel } from '@/helpers/highcharts';
import { memoizedFiltering } from '@/lib/highcharts/filters';

import {
  HighchartsPoint,
  HighchartsVariancePoint,
  Point,
  dataFilters,
  getBatchAndStopsPlotBands,
  pipePoints,
  pointProcessors,
} from './config-helpers';
import { CHART_COLORS, MEASUREMENT_CHART_COLORS } from './data-config';

interface Sensor {
  peripheralId: string;
  name: string;
  time: Array<{
    samples: Schema.Sample[];
    batches?: Schema.BatchList;
    stops?: Schema.Stop[];
  }>;
  config?: Schema.SensorConfig;
  colorIndex?: number;
}

interface Config {
  t: i18next.TFunction;
  language: string;
  height: number | string;
  sensor: Sensor;
  disableAxisLabel?: boolean;
  theme: Theme;
  showStops?: boolean;
  showStopLabels?: boolean;
}

const getConfig = ({
  t,
  height,
  sensor,
  disableAxisLabel = false,
  theme,
  showStops,
  showStopLabels,
  language,
}: Config) => {
  const type = sensor?.config?.type ?? Schema.SensorType.COUNTER;
  const isMeasurement = type === Schema.SensorType.MEASUREMENT;

  // Set a default label on the y-axis.
  const chartTimeScale = sensor?.config?.chartTimeScale ?? Schema.ChartTimeScale.MINUTE;

  const [, yAxisLabel] = disableAxisLabel ? [, ''] : determineLabel(t, sensor);

  const stops = showStops ? sensor?.time?.[0]?.stops : [];

  const index = 0;
  // NOTE: The cast here is to workaround __typename - omit recursively fails as well :(
  const querySamples = ((sensor?.time?.[0]?.samples ?? []) as Point[]).filter((sample): sample is Point => {
    return !!sample.timeRange.from && !!sample.timeRange.to;
  });

  const sensorIndex = sensor?.colorIndex || index;
  const color = isMeasurement
    ? MEASUREMENT_CHART_COLORS[sensorIndex % MEASUREMENT_CHART_COLORS.length]
    : CHART_COLORS[sensorIndex % CHART_COLORS.length];

  const now = Date.now();

  const scale = (() => {
    if (isMeasurement) {
      return 0;
    }

    switch (chartTimeScale) {
      case Schema.ChartTimeScale.DAY:
        return (1).days;
      case Schema.ChartTimeScale.HOUR:
        return (1).hours;
      case Schema.ChartTimeScale.MINUTE:
        return (1).minutes;
      default:
        return 0;
    }
  })();

  const speedProcessor = sensor?.config?.chart?.dataFilter || Schema.DataFilter.AVERAGE_SPEED;
  const processor = scale ? speedProcessor : Schema.DataFilter.NONE;

  const sampleProcessor = pointProcessors[processor];
  const voidProcessor = pointProcessors.VOID;
  const varianceProcessor = pointProcessors.VARIANCE;

  const isUptime = processor === Schema.DataFilter.UPTIME;

  // NOTE: Points are always evenly distributed from the API
  const [firstSample] = querySamples;
  const perPointSpan = firstSample
    ? new Date(firstSample.timeRange.to).getTime() - new Date(firstSample.timeRange.from).getTime()
    : Infinity;

  const composers = [
    voidProcessor({ average: isMeasurement, now }),
    sampleProcessor({ scale, average: isMeasurement, perPointSpan }),
    varianceProcessor({ isMeasurement }),
  ];

  // FIXME: Once TypeScript gets smart enough with detecting tuple length
  // move the cast to the type argument of reduce.
  const [voidData, samples, variances] = pipePoints(querySamples, composers) as [
    HighchartsPoint[],
    HighchartsPoint[],
    HighchartsVariancePoint[],
  ];

  const filteredSamples = [Schema.DataFilter.NONE, Schema.DataFilter.UPTIME].every((filter) => processor !== filter)
    ? memoizedFiltering(samples, dataFilters, {
        peripheralId: sensor?.peripheralId,
      })
    : samples;

  const plotConfig: HighchartsOptions = {
    exporting: {
      enabled: false,
    },
    chart: {
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      height,
    },
    series: [
      {
        // @ts-ignore
        boostThreshold: 1,
        animation: false,
        type: 'line',
        id: 'obj.name',
        name: '',
        data: filteredSamples,
        color: color.color,
        showInLegend: false,
        tooltip: {
          // @ts-ignore
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        shared: false,
      },
      // serie 1, variance graph used with analog
      {
        // @ts-ignore
        animation: false,
        type: 'arearange',
        name: 'Variance',
        data: variances,
        color: color.variance,
        lineWidth: 0,
        fillOpacity: 0.3,
        linkedTo: ':previous',
        tooltip: {
          // @ts-ignore
          enabled: false,
        },
        zIndex: 0,
        marker: {
          enabled: false,
        },
        visible: type === Schema.SensorType.MEASUREMENT,
      },
      // serie 2, missing data graph
      {
        // @ts-ignore
        animation: false,
        type: 'line',
        name: 'Missing data',
        data: voidData,
        dashStyle: 'Dot',
        tooltip: {
          // @ts-ignore
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        showInLegend: false,
        color: 'red',
        lineWidth: 2,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    xAxis: [
      {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: DateTimeLabelFormatBasedOnBrowserLocale(language).millisecond,
          second: DateTimeLabelFormatBasedOnBrowserLocale(language).second,
          minute: DateTimeLabelFormatBasedOnBrowserLocale(language).minute,
          hour: DateTimeLabelFormatBasedOnBrowserLocale(language).hour,
          day: DateTimeLabelFormatBasedOnBrowserLocale(language).day,
          week: DateTimeLabelFormatBasedOnBrowserLocale(language).week,
          month: DateTimeLabelFormatBasedOnBrowserLocale(language).month,
          year: DateTimeLabelFormatBasedOnBrowserLocale(language).year,
        },
        title: {
          text: disableAxisLabel ? ' ' : t(['shared:date'], { defaultValue: 'Date' }),
        },
        plotBands: (sensor?.time ?? []).length
          ? getBatchAndStopsPlotBands(sensor?.time?.[0]?.batches?.items ?? [], stops, null, theme, showStopLabels)
          : undefined,
      },
    ],
    yAxis: {
      min: (sensor?.config?.type ?? Schema.SensorType.COUNTER) === Schema.SensorType.MEASUREMENT ? undefined : 0,
      max: (sensor?.time?.[0]?.samples ?? []).every((p) => p.data.accValue === 0 || p.data.accValue === null)
        ? 10
        : undefined,
      maxPadding: 0.05,
      minRange: 0.01,
      tickInterval: isUptime ? 1 : undefined,
      endOnTick: false,
      title: {
        text: yAxisLabel,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
            animation: false,
          },
        },
      },
      area: {
        marker: {
          radius: 2,
          enabled: false,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
  };

  return plotConfig;
};

export default getConfig;


    var doc = JSON.parse('{"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Packaging"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Packaging"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"packagingId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lineId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"packagingNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"comment"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":101}}');
    doc.loc.source = JSON.parse('{"body":"fragment Packaging on Packaging {\\n  packagingId\\n  lineId\\n  name\\n  packagingNumber\\n  unit\\n  comment\\n}\\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}}');
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

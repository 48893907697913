import { useQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Radio, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import LoadingOrError from '@/components/loading/loading-or-error';
import { sensorGroups } from '@/graphql/queries';
import { useWidth } from '@/hooks';
import { useIAM } from '@/hooks/use-iam';
import * as Types from '@/types';

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    flexWrap: 'wrap',
  },
  divider: {
    height: '5px',
    margin: '30px 0',
  },
  groupItem: {
    marginLeft: '0px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  accordion: {
    flex: '1',
    transition: 'box-shadow .25s',
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  accordionDisabled: {
    color: 'grey',
    backgroundColor: '#d3d3d3',
    transition: 'box-shadow .25s',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    },
    flex: 'auto',
  },
  cardEmpty: {
    transition: 'box-shadow .25s',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  sensorChip: {
    margin: theme.spacing(0.5),
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '200px',
    },
  },
  lineTakenExplanation: {
    marginBottom: theme.spacing(),
  },
  radioButton: {
    paddingLeft: '0',
  },
}));

export type PartialSensor = NonNullable<Schema.SensorGroupsQuery['company']['groups'][0]['sensors'][0]>;

export type SensorGroup = {
  __typename: 'Group';
  availableSensors?: PartialSensor[];
  unavailableSensors?: PartialSensor[];
  id: string;
  name: string;
  sensors: PartialSensor[];
};

interface Properties {
  selectedGroup: SensorGroup | null;
  onSelectSensorGroup(group: SensorGroup | null): void;
}

const SelectSensorGroup: FC<Properties> = ({ selectedGroup, onSelectSensorGroup }) => {
  const { classes } = useStyles();
  const [t] = useTranslation();
  const iam = useIAM();
  const width = useWidth();

  const [groups, setGroups] = useState<SensorGroup[]>([]);
  const [enabledGroups, setEnabledGroups] = useState<SensorGroup[]>([]);
  const [disabledGroups, setDisabledGroups] = useState<SensorGroup[]>([]);
  const [emptyGroups, setEmptyGroups] = useState<SensorGroup[]>([]);

  const [expandedGroup, setExpandedGroup] = useState<string | false>(false);

  const { data, loading, error } = useQuery<Schema.SensorGroupsQuery, Schema.SensorGroupsQueryVariables>(sensorGroups, {
    partialRefetch: true,
    ssr: false,
    errorPolicy: 'all',
    onCompleted: (res) =>
      setGroups(
        res.company.groups.map(
          (group) =>
            ({
              ...group,
              availableSensors: group.sensors.filter((s) => s && !sensorIsPartOfLine(s)),
              unavailableSensors: group.sensors.filter((s) => s && sensorIsPartOfLine(s)),
            } as SensorGroup),
        ),
      ),
  });

  useEffect(() => {
    if (groups.length === 0) {
      return;
    }
    const sortedGroups = sortGroupsByName(groups);
    const filteredGroups = sortedGroups.filter((group) =>
      iam.hasGroupFeatures(group.id, ['Lines.Line', 'Lines.Group'], 'M'),
    );
    const newEnabledGroups = filteredGroups.filter((group) => 0 < (group.availableSensors?.length || 0));
    const newDisabledGroups = filteredGroups.filter(
      (group) => group.availableSensors?.length === 0 && group.unavailableSensors?.length !== 0,
    );
    const newEmptyGroups = filteredGroups.filter(
      (group) => group.availableSensors?.length === 0 && group.unavailableSensors?.length === 0,
    );
    setEnabledGroups(newEnabledGroups);
    setDisabledGroups(newDisabledGroups);
    setEmptyGroups(newEmptyGroups);
    if (newEnabledGroups.length > 0 && !(width === 'sm' || width === 'xs')) {
      setExpandedGroup(newEnabledGroups[0].id);
    }
  }, [groups]);

  if (!data || !data.company || !data.company.groups) {
    return <LoadingOrError loading={loading} error={error} />;
  }
  const onSelectGroup = (group: SensorGroup) => {
    onSelectSensorGroup(selectedGroup?.id === group.id ? null : group);
  };

  const renderSensorGroup = (group: SensorGroup) => {
    return (
      <SensorGroup
        key={`group-${group.id}`}
        group={group}
        selectedGroupId={selectedGroup?.id}
        expandedGroupId={expandedGroup}
        onSelectGroup={onSelectGroup}
        onExpandedChange={(newExpandedGroup) => setExpandedGroup(newExpandedGroup)}
      />
    );
  };

  if (loading) {
    return <LoadingOrError loading={loading} />;
  }

  return (
    <>
      <Typography variant="caption" className={classes.lineTakenExplanation} display="block">
        {t(['line:explanationLinesLikeTheFollowing'], {
          defaultValue: 'Sensors looking like the following:',
        })}{' '}
        <Chip
          label={
            <Typography variant="caption">
              {t(['line:sensorName'], { defaultValue: 'Sensor name' }).toLowerCase()}
            </Typography>
          }
          color="default"
        />{' '}
        {t(['line:explanationAlreadyTaken'], {
          defaultValue: ', are already part of a line, and cannot be added to a new one.',
        })}
      </Typography>
      {enabledGroups.map(renderSensorGroup)}
      {disabledGroups.map(renderSensorGroup)}
      {emptyGroups.map(renderSensorGroup)}
    </>
  );
};

interface SensorGroupProperties {
  group: SensorGroup;
  selectedGroupId: string | undefined;
  expandedGroupId: string | false;
  onSelectGroup: (group: SensorGroup) => void;
  onExpandedChange: (groupId: string | false) => void;
}

const SensorGroup: FC<SensorGroupProperties> = (props) => {
  const { group, selectedGroupId, expandedGroupId, onSelectGroup, onExpandedChange } = props;
  const { classes } = useStyles();
  const groupIsDisabled = group.availableSensors?.length === 0 && group.unavailableSensors?.length !== 0;
  const groupIsEmpty = group.availableSensors?.length === 0 && group.unavailableSensors?.length === 0;

  const onAccordionChange = (groupId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    onExpandedChange(isExpanded ? groupId : false);
  };

  return (
    <div key={'div-' + group.id} className={classes.groupItem}>
      <Radio
        onClick={() => onSelectGroup(group)}
        disabled={groupIsDisabled || groupIsEmpty}
        checked={selectedGroupId === group.id}
        color="primary"
        className={classes.radioButton}
      />
      <Accordion
        key={'accordion-' + group.id}
        elevation={3}
        expanded={expandedGroupId === group.id}
        onChange={onAccordionChange(group.id)}
        className={groupIsDisabled || groupIsEmpty ? classes.accordionDisabled : classes.accordion}
      >
        <AccordionSummary
          key={'summary-' + group.id}
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>{`${group.name} ${
            groupIsDisabled || groupIsEmpty ? ' - ' + ' no available sensors ' : ''
          }`}</Typography>
        </AccordionSummary>
        <AccordionDetails key={'details-' + group.id} className={classes.content} onClick={() => onSelectGroup(group)}>
          {groupIsEmpty
            ? null
            : sortSensorByAvailability(group.sensors.filter(Types.isTruthy)).map((sensor) => (
                <SensorItem key={`sensor-${sensor!.peripheralId}`} sensor={sensor} groupName={group.name} />
              ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
interface SensorItemProperties {
  sensor: SensorGroup['sensors'][0];
  groupName: string;
}
const SensorItem: FC<SensorItemProperties> = (props) => {
  const { sensor, groupName } = props;
  const [t] = useTranslation();
  const { classes } = useStyles();

  if (!sensor) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      key={`${groupName}-select-sensor-group-${sensor.name}-${sensor.peripheralId}`}
      title={
        <div>
          <b>{sensor.name}</b>
          <br />
          {sensor.description}
          <hr />
          {sensorIsPartOfLine(sensor)
            ? t(['line:partOfLine'], { defaultValue: 'Part of line' })
            : t(['shared:available'], { defaultValue: 'Available' })}
        </div>
      }
    >
      <Chip
        label={sensor.name}
        key={groupName + sensor.name}
        variant={sensorIsPartOfLine(sensor) ? 'filled' : 'outlined'}
        color={sensorIsPartOfLine(sensor) ? 'default' : 'primary'}
        className={classes.sensorChip}
      />
    </Tooltip>
  );
};

const sensorIsPartOfLine = (sensor: SensorGroup['sensors'][0]) => {
  return !!sensor?.peripheralInformation?.node?.peripheralId;
};

const sortGroupsByName = (groups: SensorGroup[]) => {
  const sortedGroups = [...groups];
  sortedGroups.sort((group1, group2) => {
    return group1.name.localeCompare(group2.name);
  });
  return sortedGroups;
};

const sortSensorByAvailability = (sensors: SensorGroup['sensors']) => {
  const sortedSensors = [...sensors];
  sortedSensors.sort((sensor1, sensor2) => {
    if (sensorIsPartOfLine(sensor1) && !sensorIsPartOfLine(sensor2)) {
      return 1;
    } else if (!sensorIsPartOfLine(sensor1) && sensorIsPartOfLine(sensor2)) {
      return -1;
    } else {
      return sensor1?.name.localeCompare(sensor2?.name || '') || 0;
    }
  });
  return sortedSensors;
};

export default SelectSensorGroup;

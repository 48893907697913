import { IUtils } from '@date-io/core/IUtils';
import DateFnsUtils from '@date-io/date-fns';
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
  startOfWeek,
} from 'date-fns';
import React from 'react';

import getLocale from '@/locale';

interface Options {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  additionalDigits?: 0 | 1 | 2;
  locale?: Locale;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  roundingMethod?: 'floor' | 'ceil' | 'round';
}
interface IAdditionalUtils {
  formatDistance(date: Date | number, baseDate: Date | number, options?: Options): string;
  formatDistanceStrict(date: Date | number, baseDate: Date | number, options?: Options): string;
  formatRelative(date: Date | number, baseDate: Date | number, options?: Options): string;
  formatWeekdays(formatString: string): string[];
}
export interface WithUtils {
  utils: IUtils<Date> & IAdditionalUtils;
}
const additionalUtils = (locale: Locale): IAdditionalUtils => {
  return {
    formatDistance: (date, baseDate, options = {}) => formatDistance(date, baseDate, { ...options, locale }),
    formatDistanceStrict: (date, baseDate, options = {}) =>
      formatDistanceStrict(date, baseDate, { ...options, locale }),
    formatRelative: (date, baseDate, options = {}) => formatRelative(date, baseDate, { ...options, locale }),
    formatWeekdays: (formatString) => {
      const now = new Date();
      return eachDayOfInterval({
        start: startOfWeek(now, { locale, weekStartsOn: 0 }),
        end: endOfWeek(now, { locale, weekStartsOn: 0 }),
      }).map((day: Date) => format(day, formatString, { locale }));
    },
  };
};

export const useUtils = () => {
  const utils = new DateFnsUtils();
  return Object.assign(utils, additionalUtils(utils?.locale || getLocale('enUS')));
};

export const withUtils = <T,>(WrappedComponent: React.ComponentType<T & WithUtils>): React.FunctionComponent<T> => {
  return (props) => {
    const utils = useUtils();

    return (
      <WrappedComponent
        {...(props as T)}
        utils={Object.assign(utils, additionalUtils(utils?.locale || getLocale('enUS')))}
      />
    );
  };
};
